.table-of-contents {
  color: $gray;
}

.details {
  .form-row.number-field {

    .key {

    }
    .value {

    }
  }
}

.button-new {
  border: 1px solid $border;
  padding: 5px 0;
  display: block;
  width: 100%;
  border-radius: $border-radius;
  margin-top: 10px;
}

.list-item {
  border-bottom: 1px solid $border;
  padding-bottom: 10px;
  margin-bottom: 10px;

  .list-item-name {
    margin-bottom: 10px;
    align-items: center;
    display: flex;

    > span {
      margin-right: 5px;
    }

  }

}
