// Homepage Header
//
// Header Block for the Edition Manager Homepage.
//
// Markup:
// <demo-wrapper-1210>
//  <homepage-container>
//    <header>
//      <div class="logo"><img src="../static_ui/images/jellyfish-logo.png"></div>
//      <div class="user-control">Hello, Billy Bob! | <span class="text-button">Log Out</span></div>
//    </header>
//  </homepage-container>
// </demo-wrapper-1210>
//
// Styleguide 6.4.1


header { // Sets Scope

  // Homepage Specific Header elements
  .logo {
    display: block;
    float: left;
    margin-left: -10px;

    .version {
      left: -10px;
      position: relative;
      top: -19px;
    }
  }

  .user-control {
    color: $edm-white;
    display: block;
    float: right;
    margin: 70px 21px 5px 0;

    .username {
      color: $interfacegreen;
      font-weight: 500;
    }
    .link-name{
      margin-left: 10px;
    }
    .link-name,
    .link-separator{
      margin-right: 10px;
    }
    .link-container{
      cursor: pointer;
    }
  }

}
