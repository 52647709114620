.file-upload-popup{
    .modal-body.popup{
        text-align: initial;
        margin: 0px 100px;
    }
    .modal-header-container{
        text-align: initial;
        color: #ffffff;
        font-family: 'Roboto';
        font-size: 14px;
        width: 100%;
        label{
          font-size: 21px;
          font-weight: 300;
          width: 100%;
        }
        .buttons {
          display: block;
          float: left;
          width: 100%;
      
          .right-option {
            float: right;
          }
      
          button {
            border: 1px solid $interfacewhite ;
            border-radius: 2px;
            color: $interfacewhite ;
            display: block;
            font-weight: 500;
            margin: 20px auto;
            padding: 10px 45px;
            text-align: center;
        
            &:hover {
              background: $interfacegreen;
              border: 1px solid $interfacegrey;
              color: $interfaceblack;
            }
          }
        }
      }
      .alert {
       line-height: 40px;
        padding-left: 10px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
        text-align: left;
        .count{
            font-weight:bold;
        }
      }
      .alert-success {
        border-color: #50E9BA;
        height:40px;
        margin-top:20px;
      }
      
      .alert-error {
        border-color: #a94442;
      }
      
      .alert-warning {
        border-color: #FFC003;
        padding-left:0px;
      }
    
      .alert-message{
        border-bottom: 1px solid #7A8791;
        padding-left:10px;
        padding-bottom: 10px;
      }
      
      .file-selection-list{
        line-height: 14px;
        color: #B9BBC0;
        .file-selection-header{
            font-style: italic;
            padding-left: 20px;
            padding-bottom: 10px;
        }
        .file-selection-rows{
            border-top: 1px solid #7A8791;
            padding-top:10px;
            max-height: 400px;
            overflow: auto;
        }
        .file-selection-row{
          padding:10px 0px 10px 20px;
          width:100%;
          > div{
              width:20%;
              text-align: center;
          }
          > div.file-name{
            &.template-update {
              width: 100%;
            }
            text-align: initial;
            &.template-update {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
}
