.code-editor-container {
  flex: 0 1 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
}

.code-editor-wrap {
  flex: 1 1 auto;
  position: relative;
  margin-right: 15px;

  .ace_editor {
    background-color: transparent;
  }

  .ace_gutter {
    background: rgba(38, 38, 38, 0.33) !important;
  }

}

.compile-error {
  border-color: $error !important;
}

.compile-error-message {
  position: absolute;
  bottom: 0;
  margin-bottom: 2px;
  width: 100%;
  font-size: 14px;
  color: $light-gray;
  background: $section-background;
  padding: 8px 4px;
}

.code-editor-snippets,
.code-editor-variables,
.code-editor-assets {
  flex: 0 1 150px;
  position: relative;
  margin-right: 15px;
  color: $gray;
  overflow: auto;
  
  ul{
    margin-top: 30px;
    padding: 5px 0;
  }
  
  li {
    font-family: 'Roboto Mono';
  }

  label {
    color: $white;
    font-size: 14px;
    padding-bottom: 15px;
    display: block;
    background: #1E2124;
    position: fixed;
    width: 144px;
  }

  button {
    display: block;
    padding: 2px 0;
    color: $gray;
    font-size: 12px;
    font-family: 'Roboto Mono';
    font-weight: normal;
    transition: color 0.2s;

    &:hover {
      color: $white;
    }

  }

  .code-editor-assets-hierarchy{

    li{
      @for $i from 1 through 10 {
        &.level_#{$i} {
          padding-left: #{$i * 15}px;
        }
      }
      position: relative;
      padding-top: 5px;

      button{
        position: relative;
        text-align: left;

        &:before{
          position: absolute;
          content: '';
          height: 1px;
          width: 10px;
          background-color: #fff;
          margin-left: -15px;
          top: 10px;
        }
      }

      &.level_1{

        &:before{
          position: absolute;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ffffff;
          margin-left: -15px;
        }
        &:after{
          display: none;
        }
        button{
          &:before{
            display: none;
          }
        }
      }
    }


  }
}

.code-editor-assets {
  margin-right: 0;
}
