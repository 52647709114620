// Page Transitions
@keyframes squish-enter {
  0% {
    opacity: 0;
    transform: scale(.975);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes squish-exit {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.975);
  }
}

// Animation for colorbar
@keyframes colorbar-animate {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

// Search Results Loading Animation
@keyframes pulse-loading {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes hidesearch {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
// Expand/Collapse animations for recent editions panel
@keyframes collapse-recent {
  from {height: 270px;}
  to {height: 63px;}
}
@keyframes expand-recent {
  from {height: 63px;}
  to {height: 270px;}
}
