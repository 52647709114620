
$black70percent: rgba(0, 0, 0, .7);  // Modal Overlay color
$interfacegrey: #7a8791; // Primary Text color - Modals
$interfacewhite: #fff; // White - Button color and hover color for interface icons
$interfacedarkgrey: #1c1f21; // background color for selection dropdowns
$interfacegreen: #50e9ba; // Hover color for button elements
$interfacepink: #ef4771; // Validation Text
$interfaceblack: #000; // Button black
$interfacegrey: #797c80;


.black70percent {
  background: $black70percent;
  color: $edm-white;
}

.interfacegrey {
  background: $interfacegrey;
  color: $edm-white;
}

.interfacewhite {
  background: $interfacewhite;
  color: $edm-black;
}

.interfacedarkgrey {
  background: $interfacedarkgrey;
  color: $edm-white;
}

.interfacegreen {
  background: $interfacegreen;
  color: $edm-white;
}

.interfacepink {
  background: $interfacepink;
  color: $edm-white;
}

.interfaceblack {
  background: $interfaceblack;
  color: $edm-white;
}
