// Edition Manager Core Styles
//
// Core Styles for the Edition manager
//
// Styleguide 6.3

// Homepage
//
// Styleguide 6.4

// Edition Details page
//
// Styleguide 6.5




// Primary background and scrollbars
//
// The Edition Manager uses the $edm-darkgrey background.
// Scrollbars are implemented for ul elements, used exclusively
// for listing Recent Editions and the page body itself.
//
// Markup:
// <demo-wrapper-600>
// <ul class="demo-list">
//  <li>List Item 1</li>
//  <li>List Item 2</li>
//  <li>List Item 3</li>
//  <li>List Item 4</li>
//  <li>List Item 5</li>
//  <li>List Item 6</li>
//  <li>List Item 7</li>
//  <li>List Item 8</li>
//  <li>List Item 9</li>
//  <li>List Item 10</li>
//  <li>List Item 11</li>
//  <li>List Item 12</li>
//  <li>List Item 13</li>
// </ul>
// </demo-wrapper-600>
//
// Styleguide 6.3.1
body {
  background: $edm-darkgrey;
}

#editionmanager {
  height: 100%;
  overflow-y: scroll;

  .squish-exiting {
    animation: squish-exit .25s ease-in 1;
    opacity: 0;
  }

  .squish-entering {
    // hide it (offscreen or absolutely) while the exiting transition finishes
    animation: none; // Kills default _takeover animation
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;

  }

  .squish-entered {
    animation: squish-enter .25s ease-out 1;
  }
}

homepage-wrapper,
editiondetails-wrapper,
demo-wrapper-1210,
demo-wrapper-1170 {
  background: $edm-darkgrey;
  color: $edm-white;
  display: block;
  font-family: $edm-fonts;
}

demo-wrapper-600 {
  background: $edm-darkgrey;
  color: $edm-white;
  display: block;
  float: left;
  font-family: $edm-fonts;
  padding: 10px;
  width: 600px;
}

demo-wrapper-1210 {
  float: left;
  width: 1210px;
}

demo-wrapper-1170 {
  float: left;
  width: 1170px;
}

.demo-list {
  border: 1px solid $edm-grey;
  height: 100px;
  overflow-y: scroll;
  padding: 5px;
}

.demo-block {
  border: 1px solid $edm-grey;
  display: block;
  float: left;
  padding: 10px;

  div {
    border: 1px solid $edm-lightgrey;
    border-radius: 10px;
    display: block;
    float: left;
    margin: 10px;
    padding: 10px;
    width: 90%;

    i,
    span {
      display: block;
      float: left;
      height: 32px;
      text-align: left;
    }

    i {
      margin-right: 10px;
      width: 32px;
    }

    span {
      padding-top: 5px;
    }
  }
}

body,
ul,
.search-results-body {

  &::-webkit-scrollbar-track {
    background-color: $edm-scrollbar-background;
    border-radius: 10px;
    box-shadow: inset 0 0 6px $edm-scrollbar-shadow;
  }

  &::-webkit-scrollbar {
    background-color: $edm-scrollbar-background;
    border-radius: 12px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $edm-scrollbar-bar;
    border: 0;
    border-radius: 10px;
    box-shadow: inset 0 0 6px $edm-scrollbar-shadow;
  }
}

// Icons
//
// The Edition Manager uses the $edm-darkgrey background.
// Scrollbars are implemented for ul elements, used exclusively
// for listing Recent Editions and the page body itself.
//
// Markup:
// <demo-wrapper-600>
// <div class="demo-block">
//  <div><i class="chevron-large"></i><span> .chevron-large - Large Chevron</span></div>
//  <div><i class="chevron-small"></i><span> .chevron-small - Small Chevron</span></div>
//  <div><i class="plus-small"></i><span> .plus-small - Small Plus</span></div>
// </div>
// </demo-wrapper-600>
//
// Styleguide 6.3.2


// NB. Not in use
.chevron-large {
  @include chevron-icon;;
  background-color: $edm-white;
  display: inline-block;
  height: 32px;
  -webkit-mask-size: 32px 32px;
  width: 32px;
}

// NB. Used in panels/EditionManager/Homepage.js
.chevron-small {
  @include chevron-icon;;
  background-color: $edm-white;
  display: inline-block;
  height: 16px;
  -webkit-mask-size: 16px 16px;
  width: 16px;
}

// NB. Used in panels/Timeline/index.js, panels/EditionManager/{EditionAdvisorLinkDetails,Homepage}.js, 
.chevron-xtrasmall {
  @include chevron-icon;;
  background-color: $edm-white;
  display: inline-block;
  height: 10px;
  -webkit-mask-size: 10px 10px;
  width: 10px;
}

// TODO Unused, remove
.ascending {

  &:hover {

    i {
      transform: rotate(180deg);
    }
  }
}

// TODO Unused, remove
.descending {

  &:hover {

    i {
      background: $edm-lightgrey;
    }
  }
}

.filter {
  cursor: pointer;

  &:hover {

    i {
      background-color: $edm-white;
    }
  }

  i {
    background-color: $edm-lightgrey;
    margin-left: 5px;
    margin-top: 3px;
  }

  &.active {

    .asc {
      transform: rotate(180deg);
    }

    i {
      background-color: $edm-white;
      margin-left: 5px;
      margin-top: 3px;
    }
  }
}

.plus-small {
  @include plus-icon;;
  background-color: $edm-white;
  display: inline-block;
  height: 10px;
  margin-top: 2px;
  -webkit-mask-size: 10px 10px;
  width: 10px;
}

// Global Homepage/Edition Details elements
homepage-container,
editiondetails-container,
.demo-wrapper {
  display: block;
  margin: 40px auto;
  width: 1210px;

  .text-button {
    color: $edm-white;
    cursor: pointer;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  .chevron {
    background: $edm-white;
    display: inline-block;
    height: 16px;
    margin: 4px 5px 0;
    -webkit-mask: url('../images/script-collapsed-light.png') 50% 50%;
    width: 16px;

  }

  // Shared width/float controls for homepage layout elements
  .recent-editions,
  .edition-search,
  .search-results,
  .search-results-loading,
  .edition-versionfilter {
    display: block;
    float: left;
    margin: 0 20px;
    width: 1170px;
  }

  // breakout element for edition summary to have more padding below.
  .edition-summary {
    display: block;
    float: left;
    margin: 0 20px 25px;
    width: 1170px;
  }

  // TODO Consolidate, only strict, direct consumer is Homepage 
  header {
    display: block;
    float: left;
    width: 100%;

    // TODO remove in favor of app/scripts/panels/EditionManager/header.scss
    &.editiondetails { // Restricts Header to 1170 and centered on Edition Details Page
      display: block;
      float: none;
      height: 107px;
      margin: 0 auto 10px;
      width: 1170px;
    }
  }

  section {
    display: block;
    float: left;

    &.recent-editions { // Homepage - Recent Editions Section
      background: $edm-coolgrey;
      height: 270px;
    }

    &.edition-search {
      height: 41px;
      padding: 70px 0;
    }

    &.edition-versionfilter {
      padding: 70px 0;
    }

    &.edition-summary {
      background: $edm-coolgrey;
    }
  }
}
