// Interface Element Style Variables
//
// Style Variables for use within the Edition Manager. These
// variables are scoped only for use in the edition manager section
// and Jellyfish and should not be used in other parts of the app.
//
// Styleguide 2.1


// Primary Colors:
//
// Colors used in new interface elements. These color variables are local to the new interface components identified in this section.
// Markup:
// <div class="colorswatch black70percent">$black70percent : rgba(0, 0, 0, .7)</div>
// <div class="colorswatch interfacegrey">$interfacegrey : #7a8791</div>
// <div class="colorswatch interfacewhite">$interfacewhite : #fff</div>
// <div class="colorswatch interfacedarkgrey">$interfacedarkgrey : #1c1f21</div>
// <div class="colorswatch interfacegreen">$interfacegreen : #50e9ba</div>
// <div class="colorswatch interfacepink">$interfacepink : #ef4771</div>
// <div class="colorswatch interfaceblack">$interfaceblack : #000</div>
// <div class="colorswatch interfacegrey">$interfacegrey : #797c80</div>
//
// Styleguide 2.1.1




$modal-background: rgba(25, 28, 31, 1);
$modal-shadow: rgba(0, 0, 0, .75);
