// Edition Version Filter - DISABLED
//
// Markup:
// <demo-wrapper-1210>
//  <editiondetails-container>
//  <section class="edition-versionfilter">
//   <div class="versions">
//     <select>
//       <option>All Versions</option>
//       <option>Release</option>
//       <option>Major</option>
//       <option>Minor</option>
//       <option>Latest</option>
//     </select>
//   </div>
//   <div class="start-date">
//     <span>Date Range: </span>
//     <select>
//       <option>Month</option>
//       <option>January</option>
//       <option>February</option>
//       <option>March</option>
//       <option>April</option>
//     </select>
//     <select>
//       <option>Year</option>
//       <option>2017</option>
//       <option>2016</option>
//       <option>2015</option>
//       <option>2014</option>
//     </select>
//   </div>
//   <div class="date-seperator">To</div>
//   <div class="end-date">
//     <span>&nbsp;</span>
//     <select>
//       <option>Month</option>
//       <option>January</option>
//       <option>February</option>
//       <option>March</option>
//       <option>April</option>
//     </select>
//     <select>
//       <option>Year</option>
//       <option>2017</option>
//       <option>2016</option>
//       <option>2015</option>
//       <option>2014</option>
//     </select>
//     <button>Filter</button>
//   </div>
//  </section>
//  </editiondetails-container>
// </demo-wrapper-1210>
//
// Styleguide 6.5.3

.edition-versionfilter {

  button {
    @include editionman-button;;
    margin-left: 20px;

  }

  select {
    @include editionmanager-select;
  }

  .edition-current-name {
    color: $edm-white;
    font-weight: 700;
  }

  .start-date,
  .end-date {
    display: inline-block;
    float: left;
    margin-left: 30px;

    span {
      color: $edm-lightgrey;
      display: inline-block;
      font-size: 11pt;
      padding-bottom: 10px;
      width: 100%;


    }

  }

  .end-date {
    width: 500px;
  }

  .start-date {
    width: 252px;
  }

  .versions {
    display: inline-block;
    float: left;
    margin-top: 30px;
    width: 200px;
  }

  .date-seperator {
    display: inline-block;
    float: left;
    height: 30px;
    margin-left: 11px;
    padding-top: 39px;
  }
}
