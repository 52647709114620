.action {
  @extend .connection;

  .add-behavior {
    margin-top: 10px;
  }

  .statement {

    .remove-connection {
      right: 12px;
    }

  }

  .behavior {
    border-top: 1px solid rgba($gray, 0.25);
    padding-top: 10px;
    position: relative;

    &:last-child {
      border-bottom: 1px solid rgba($gray, 0.25);
      padding-bottom: 10px;
    }

    .remove-connection {
      right: 0;
      text-align: right;
      top: 14px;

      i {
        padding: 0;
      }

    }

  }

  .code-block {
    padding-top: 10px;
    display: block;
    overflow: hidden;
    width: 175px;
    height: 60px;
    position: relative;
    cursor: pointer;

    .code-block-overlay {
      position: absolute;
      width: 175px;
      height: 50px;
      z-index: 2;
      top: 10px;
      background: linear-gradient(to bottom, rgba(34,39,45,0) 0%,rgba(34,39,45,1) 100%);
    }

  }

  pre {
    position: relative;
    overflow: hidden;
    code {
      font-family: Consolas, monaco, monospace;
      color: $gray;
      transition: color 200ms;
    }
    &:hover {
      cursor: pointer;
      code {
        color: $light-gray;
      }
    }
  }

}
