/* New icon maps - 64 bit encoded svg files */

@mixin chevron{
// Source image - chevron.svg
// Just add background color
  -webkit-mask:url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%0A%3Csvg%20width%3D%22154px%22%20height%3D%2230px%22%20viewBox%3D%220%200%20154%2030%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%0A%20%20%20%20%3C%21--%20Generator%3A%20Sketch%2039.1%20%2831720%29%20-%20http%3A//www.bohemiancoding.com/sketch%20--%3E%0A%20%20%20%20%3Ctitle%3EChevron%3C/title%3E%0A%20%20%20%20%3Cdefs%3E%3C/defs%3E%0A%20%20%20%20%3Cg%20id%3D%22Script-Sprites%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20id%3D%22script-sprites%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22Caret%22%20fill%3D%22%23000%22%20points%3D%226%204%202%208%200%206%206%200%207%201%2012%206%2010%208%22%3E%3C/polygon%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22Caret%22%20fill%3D%22%23000%22%20points%3D%2238%204%2034%208%2032%206%2038%200%2039%201%2044%206%2042%208%22%3E%3C/polygon%3E%0A%20%20%20%20%20%20%20%20%3C/g%3E%0A%20%20%20%20%3C/g%3E%0A%3C/svg%3E");
  -webkit-mask-position:0px 0px;
}

@mixin save{
  -webkit-mask:url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg width='17px' height='17px' viewBox='0 0 17 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 3.7 %2828169%29 - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Esave%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='save' fill='%23000'%3E%3Cg transform='translate%280.406593, 0.727273%29'%3E%3Cpath d='M13.2506546,0 L12.1129721,0 L12.1129721,5.53692635 L3.2792024,5.53692635 L3.2792024,0 L0,0 L0,15.4099998 L15.3921745,15.4099998 L15.3921745,2.17928084 L13.2506546,0 L13.2506546,0 Z M13.1837321,13.2752813 L2.14151993,13.2752813 L2.14151993,7.7383549 L13.1837321,7.7383549 L13.1837321,13.2752813 L13.1837321,13.2752813 Z' id='Shape'%3E%3C/path%3E%3Crect id='Rectangle-path' x='8.62637363' y='0' width='2.35162446' height='4.68831169'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  -webkit-mask-position:0px 0px;
}
//
