
#interface {
  padding-top: 44px;
  width: 100%;
}

.collapse-bar {
  background: $black;
  color: $interfacewhite;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  padding: 5px 5px 0;
  position: absolute;
  top: 0;
  width: 100%;

  .card-label {
    display: block;
    float: left;
  }

  .close {

    border-radius: 12px;
    color: $gray;
    cursor: pointer;
    display: block;
    float: right;
    font-weight: 900;
    margin: 0 -2px 0 0;
    padding: 0 4px 2px;

    &:hover {
      color: $interfacewhite;
    }
  }
}

.card-content {
  margin-top: 25px;
}

.top-row {
  position: relative;
  z-index: 12;
}

.top-card {
  background: $widgets-background;
  height: 80px;
  overflow: none;
  display: grid;
  grid-template-columns: 75% 25%;

  .selections {
    padding: 1rem;  
    display: grid;
    grid-template-rows: 1fr 1fr;

    .first-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .second-row {
      display: grid;
      grid-template-columns: 0.05fr 1fr;
      // Override global input margin/padding
      input { margin: auto 0; }
      label { color: $interfacewhite; margin: auto 0; }
    }
  }
}

.timeline-wrap {
  border-top: 3px solid $gray;
  bottom: 0;
  box-shadow: 0 -6px 8px $black35percent;
  position: absolute;
  width: 100%;
  z-index: 11;
}

.timeline-wrap.free {
  border-top: none;
}

.card {

  &::-webkit-scrollbar {
    width: 1px;
  }
}

.column {

  &.left {
    background: $panel-background;
    box-shadow: 0 2px 14px $black30percent;
    float: left;
    position: relative;
    z-index: 10;

    &.zpush {
      z-index: 15;
    }
  }

  &.right {
    background: $panel-background;
    box-shadow: 0 -2px 14px $black30percent;
    float: right;
    position: relative;
    z-index: 10;

    &.zpush {
      z-index: 15;
    }
  }

  &.free {
    background: $viewport-background;
    position: absolute;
    width: 100%;
    z-index: 2;

    .card {
      height: 100%;
    }

    .viewport {
      background: $viewport-background;
    }
  }
}

.row {
  width: 100%;
}
