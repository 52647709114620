.toolbar-panel {
  background: $interfacedarkgrey;
}

.notifier {
  background: $interfacegreen;
  border-radius: 6px;
  color: $interfacewhite;
  display: block;
  font-family: $edm-fonts;
  font-size: 14px;
  font-weight: 500;
  left: 45%;
  max-width: 400px;
  min-width: 250px;
  opacity: 0;
  padding: 3px 30px 2px;
  position: absolute;
  text-align: center;
  top: 5px;

  &.triggered {
    animation-duration: 2s;
    animation-name: notification-animate;
  }
}

.toggle-check {
  font-size: 14px;

  .checkmark {
    color: $black;
    display: inline-block;
    padding-right: 15px;
    width: 10px;
  }

  .label {
    color: $black;
    display: inline-block;
  }
}
