
.timeline-wrap {
  border-top: 3px solid $gray;
  bottom: 0;
  position: absolute;
  width: 100%;

}

.timeline-card {
  bottom: 0;
  display: block;
  //height: 300px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 11;
}

.grabber {
  cursor: ns-resize;
  display: block;
  float: left;
  height: 16px;
  position: absolute;
  top: -12px;
  width: 100%;
  z-index: 10000;
}

.timeline-controls-left {
  display: block;
  padding: 10px 0 0 10px;
  position: absolute;
  width: 250px;
  z-index: 30000;

  button,
  span,
  div,
  label {
    display: block;
    float: left;
  }

  button {
    margin-top: 3px;
    padding: 0 7px 0 0;
  }

  .secondary-control {
    margin-top: 10px;
  }

  .controls-timecode {
    margin: 11px 5px 0 0;
  }

  .controls-timecode-frame {
    margin: 11px 0 0 5px;
    width: 75px;

    label {
      margin: -1px 8px 0 0;
    }

    .input-wrap {
      margin: 0 3px;
      min-width: 10px;

      input,
      div {
        min-width: 8px;
      }
    }

  }
}

.timeline-ruler {
  padding-bottom: 35px;

  .chevron-xtrasmall {
    cursor: pointer;
    margin: 0 5px 0 -5px;

    &.asc {
      transform: rotate(180deg);
    }
  }
}

.easing-off {
  background: url('../images/easing.svg') no-repeat;
  background-size: 20px 20px;
  margin: 7px 10px 0 0;
  opacity: .5;
  overflow: visible;
  padding-top: 20px;
  width: 20px;

  .easing-options,
  .easing-arrow {
    display: none;
  }
}

.easing-on {
  background: url('../images/easing.svg') no-repeat;
  background-size: 20px 20px;
  margin: 7px 10px 0 0;
  overflow: visible;
  padding-top: 20px;
  width: 20px;

  .easing-options,
  .easing-arrow {
    display: none;
  }

  &:hover {
    background: url('../images/easing-hover.svg') no-repeat;
    height: 200px;

    .easing-options,
    .easing-arrow {
      display: block;
    }
  }
}

.easing-arrow {
  border-bottom: 7px solid $timeline-easing-options;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  height: 0;
  margin: 4px 0 0 3px;
  width: 0;
}

.easing-options {
  background: $timeline-easing-options;
  border-radius: 4px;
  box-sizing: content-box;
  color: $black;
  margin-top: 11px;
  padding: 15px 10px 10px 15px;
  position: absolute;
  width: 105px;

  span {
    color: $black;
    display: block;
    float: left;
    font-weight: 400;
    padding: 2px 0;
    width: 100%;
  }
}
