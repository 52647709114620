.takeover-outer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  // opacity: 1;
  animation: fade-in 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) 1;
  overflow-y: scroll;

  .takeover-inner {
    left: 5%;
    position: absolute;
    top: 10%;
    z-index: 1;
  }

  .takeover-under {
    background-color: $takeover-background;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
