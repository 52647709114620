// Edition Search Controls
//
// Allows for searching or the creation of a new unversioned edition on the edition manager homepage.
//
// Markup:
// <demo-wrapper-1210>
//  <homepage-container>
//    <section class="edition-search">
//      <div class="search-bar">
//        <input class="search-bar-input" type="text" placeholder="Search..."></input><button class="search-clear"></button>
//      </div>
//     <button class="create-new-edition"><i class="plus-small"></i> Create New Edition</button>
//    </section>
//  </homepage-container>
// </demo-wrapper-1210>
//
// Styleguide 6.4.3


.edition-search { // Sets Scope

  .search-bar {
    border-bottom: 1px solid $edm-lightgrey;
    float: left;
  }
  .buttons {

        float: right;
        height: 41px;
        overflow: hidden;
        position: relative;
        z-index: 10;
        button {
            @include editionman-button;;
            margin-right: 10px;
        }

        &.advisor-search-button{
          button:last-child{
            margin-right: 0px;
          }
          button.selected{
            background: #50e9ba;
            color: #000;
          }
        }
  }

  .filter-text{
    font-size: 16px;
    margin-right:10px;
  }

  .new-edition-options {
    float: right;
    height: 41px;
    overflow: hidden;
    position: relative;
    width: 200px;
    z-index: 10;

    &:hover {
      height: auto;
      overflow: visible;

      .selector {
        background: $edm-mintgreen;
        color: $edm-black;

        .chevron-xtrasmall {
          background-color: $edm-black;
          margin-left: 3px;
        }
      }
    }
    .selector {
      @include editionman-button;;
      float: right;
      width: 100%;

      .chevron-xtrasmall {
        margin-left: 3px;
      }
    }

    .create-new-edition {
      @include editionman-button;;
      float: right;
      padding: 6px 20px;
      width:100%;
    }
  }

  .search-bar-input {
    background: url('../images/editionmanager/search-icon.svg') no-repeat;
    background-position: 7px 7px;
    border: 0;

    color: $edm-white;
    display: inline-block;
    float: left;
    font-size: 18px;
    outline: 0;
    padding: 8px 40px;
    width: 500px;

  }

  .search-clear {
    background: url('../images/editionmanager/remove-icon.svg') no-repeat;
    background-position: 7px 14px;
    border: 0;
    color: $edm-white;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 18px;
    height: 41px;
    outline: none;
    padding: 12px 0;
    width: 32px;
  }
}
