// New Edition Modal
//
// Modal appears upon clicking the + New Edition button from the Edition Manager homepage.
// Markup:
// <div class="interfacegrey" style="padding:50px; width:800px; height:500px;" >
// <div class="modal-wrap">
// <div class="modal-content" style="width: 700px; height: auto;">
//   <header class="modal-header">
//     <h1></h1><button>Close</button>
//   </header>
//   <div class="modal-body popup">
//     <div class="newedition-wrap">
//       <label>Create a New Edition</label>
//       <div class="edition-name-block">
//         <input type="text" class="edition-name" placeholder="Name Your edition" value="">
//         <input type="button" class="clear-name" value="">
//       </div>
//       <label class="validation"><span class="hidden">Edition name is required.</span></label>
//       <select>
//         <option value="">Assign Your Asset Set</option>
//         <option value="31">zz - MANDARIN AMERICAN | TRANSPERFECT MANDARIN | MALE</option>
//         <option value="5">dd - STANDARD ENGLISH | DEB DOETZER | FEMALE</option>
//         <option value="7">ca - STANDARD ENGLISH | CHRIS AGOS | MALE</option>
//         <option value="19">jm - STANDARD SPANISH | TRANSPERFECT SPANISH | FEMALE</option>
//         <option value="22">jg - STANDARD SPANISH | TRANSPERFECT SPANISH | MALE</option>
//         <option value="14">ar - ARAB AMERICAN | TRANSPERFECT ARABIC | MALE</option>
//         <option value="15">cc - CANTONESE AMERICAN | TRANSPERFECT CANTONESE | FEMALE</option>
//         <option value="16">cm - MANDARIN AMERICAN | TRANSPERFECT MANDARIN | MALE</option>
//         <option value="24">pl - POLISH AMERICAN | TRANSPERFECT POLISH | FEMALE</option>
//         <option value="17">ra - RUSSIAN AMERICAN | TRANSPERFECT RUSSIAN | FEMALE</option>
//         <option value="18">va - VIETNAMESE AMERICAN | TRANSPERFECT VIETNAMESE | FEMALE</option>
//         <option value="32">JJ - CANTONESE | JEANNIE JOHNSON | FEMALE</option>
//         <option value="10">p0 - STANDARD ENGLISH | NONE | UNKNOWN</option>
//         <option value="11">p1 - STANDARD SPANISH | NONE | UNKNOWN</option>
//         <option value="8">m1 - QUEEN'S ENGLISH | PETA JOHNSON | FEMALE</option>
//         <option value="3">aa - AFRICAN-AMERICAN ENGLISH | JEANNIE JOHNSON | FEMALE</option>
//         <option value="1">en - AFRICAN-AMERICAN ENGLISH | MICHELLE SOBEL | FEMALE</option>
//         <option value="23">m2 - STANDARD ENGLISH | PETA JOHNSON | FEMALE</option>
//         <option value="2">sp - STANDARD SPANISH | NICOLE OTT-YUNIS | FEMALE</option>
//         <option value="4">mq - STANDARD SPANISH | MARU PARDO QUINTERO | FEMALE</option>
//         <option value="6">cp - STANDARD SPANISH | MONICA CECILIA PRADO | FEMALE</option>
//         <option value="9">a1 - ARAB AMERICAN | ARGO ARABIC | FEMALE</option>
//         <option value="21">cg - CANADIAN FRENCH | CANADIAN FRENCH TRANSPERFECT | FEMALE</option>
//         <option value="13">kc - CANTONESE AMERICAN | KAISER CANTONESE | FEMALE</option>
//         <option value="12">km - MANDARIN AMERICAN | KAISER MANDARIN | FEMALE</option>
//         <option value="25">b1 - STANDARD ENGLISH | DEB DOETZER | FEMALE</option>
//         <option value="26">b2 - CANTONESE | TRANSPERFECT CANTONESE | FEMALE</option>
//         <option value="27">ad - FRENCH | TRANSPERFECT FRENCH  | FEMALE</option>
//       </select>
//       <label class="validation"><span class="hidden">Asset Set selection is required.</span></label>
//       <div class="buttons">
//         <button class="left-option">Create Edition</button>
//         <button class="right-option">Cancel</button>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// </div>
//
// Styleguide 2.2.2

.newedition-wrap {
  color: $edm-lightgrey;
  font-family: 'Roboto';
  font-size: 16px;
  text-align: left;
  width: 650px;

  textarea{
    border-bottom: 1px solid $interfacegrey !important; // Overrides "underline" behavior for other inputs
    padding: 10px;
    
    &::placeholder {
      color: $edm-lightgrey;
    }
  }

  input {
    border: 0 !important; // Overrides "underline" behavior for other inputs
    border-bottom: 1px solid $interfacegrey !important; // Overrides "underline" behavior for other inputs
    color: $interfacewhite;
    float: left;
    font-weight: 400;
    height: 35px;
    line-height: 1.3em;
    margin: 10px 0 0;
    padding: 10px;
    width: calc(100% - 30px);

    &::placeholder {
      color: $edm-lightgrey;
    }

    &:hover {
      appearance: none;
      border: 0;
    
    }
  }



  select {
    @include chevron-select;;
    background-position: 98% 50%;
    border: 1px solid $interfacegrey;
    border-radius: 4px;
    color: $edm-lightgrey;
    float: left;
    font-weight: 400;
    height: 50px;
    line-height: 1.3em;
    margin: 10px 5% 0;
    padding: 10px;
    width: 94%;

    &:hover {
      appearance: none;
      background-position: 98% 50%;
      border: 1px solid $interfacegrey;
      border-radius: 6px;
      width: 94%;
    }
  }

  .edition-name-block {
    margin-left: 5%;
  }

  .edition-name {
    float: left;
  }
  .link-expiration-container{
    display: flex;
    width:100%;
    .link-expiration{
      max-width: 150px;
      &.expired{
        color: #6F6F6F;
      }
      &.invalid{
        color: #F54C7F;
      }
    }
  }
  .error{
    color:#F54C7F;
    padding-bottom:10px;
  }

  


  .share-name-block{
  .edition-comments{
    border:1px solid $interfacegrey !important;
    border-radius: 4px;
  }

  .validation{
    padding: 0px;
  }
}


  .clear-name {
    background: url('../images/editionmanager/remove-icon.svg') no-repeat;
    background-color: none;
    background-position: 3px 7px;
    float: left;
    width: 30px;

    &:hover {
      width: 30px;
    }
  }

  // this class should come from sharelink.scss
 .share-link-label{
    margin: 8px 0% !important;
    // input, textarea{
    //   border: 1px solid $interfacegrey !important; // Overrides "underline" behavior for other inputs
    // }
  }

  .share-name-block {
    margin-left: 5%;
    margin-right: 5%;
  }
  .margin-bottom{
    margin-bottom: 30px;
  }

  
  label {
    float: left;
    font-size: 24px;
    font-weight: 300;
    margin: 8px 5%;
    width: 100%;
  }

  .buttons {
    display: block;
    float: left;
    margin-left: 30px;
    width: 90%;

    .left-option {
      float: left;
    }

    .right-option {
      float: right;
    }

  }

  .validation {
    height: 38px;
    margin: 0 0 6px 13px;
    padding: 0 0 0 30px;

    span {
      color: $interfacepink;
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
    }

    .hidden {
      display: none;
    }
  }

  button {
    border: 1px solid $interfacewhite ;
    border-radius: 4px;
    color: $interfacewhite ;
    display: block;
    font-weight: 500;
    margin: 20px auto;
    padding: 10px 45px;
    text-align: center;

    &:hover {
      background: $interfacegreen;
      border: 1px solid $interfacegrey;
      color: $interfaceblack;
      &:disabled{
        background: inherit;
        color: $interfacewhite;
        border: 1px solid $interfacewhite;
      }
    }
    
  }
}
