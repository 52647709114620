.loading-overlay {

  &:empty {
    display: none;
  }

  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10000;
  background-color: rgba(0,0,0,0.25);

  div.spinner {
    width: 125px;
    height: 125px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -62.5px 0 0 -62.5px;
    animation: spin 1s linear infinite;

    @keyframes spin {
      0%   { transform: rotate(0deg) }
      100% { transform: rotate(360deg) }
    }

  }

}

.pending {
  @keyframes spin-circ {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-dash {
    0% {
      stroke-dasharray: 1, 800;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 400, 400;
      stroke-dashoffset: -200px;
    }
    100% {
      stroke-dasharray: 800, 1;
      stroke-dashoffset: -800px;
    }
  }
  transform-origin: center;
  animation: spin-dash 1.5s ease-in-out infinite,
  spin-circ 2s linear infinite;
  animation-direction: alternate;
}
