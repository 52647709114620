.card {
  flex: 1 1 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  border: $card-border;
  background: $card-background;
  border-radius: $border-radius;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);

  &.selected {
    border: 1px solid rgba($white, 0.2);
  }


  &::-webkit-scrollbar-thumb {
      background-color: rgba($gray,0);
      transition: background-color 1s;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
        background-color: rgba($gray,0.8);
    }
  }


  .card-title {
    border-bottom: 1px solid $dark-gray;
    font-weight: 300;
    margin: 0px 10px 5px;
    padding: 20px 0 15px;

    input[type=text] {
      font-weight: 300;
      color: $white;
      width: 100%;

    }

    + .card-subsection {
      padding-top: 0;
    }

  }

  .card-subsection-title {
    font-weight: 400;
    background: $section-background;
    color: $gray;
    padding: 3px 0 3px 10px;
    margin: 0 0 15px;
    position: relative;

    i {
      position: absolute;
      right: 10px;
      top: 8px;
      cursor: pointer;
    }

    + .card-subsection {
      padding-top: 0;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px 0 10px;
      position: relative;
      float: right;
      margin-top: -5px;
      button {
        &.chevron {
          cursor: pointer;
          display: inline-block;
          width: 12px;
          padding: 0;
          margin-left: 5px;
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
          transition: all 0.3s ease;

          &.collapsed {
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
          }

          i {
            position: initial;
            right: auto;
            top: auto;
            cursor: pointer;
            background-size: 200%;
          }
        }
        &.remove {
          cursor: pointer;
          display: inline-block;
          width: 12px;
          padding: 0;
          margin-left: 5px;
          margin-top: -1px;
          i {
            background: $section-background;
            position: relative;
            top: 0;
            right: 0;
          }
        }
      }

      i {
        background-color: #ffffff;
      }
    }

  }

  .card-subsection {
    padding: 10px;
    margin-bottom: 15px;

    &.full-width {
      padding: 10px 0;
    }

    &.collapsed{
      max-height: 0;
      padding-bottom: 0;
      overflow: hidden;
    }

    &:empty {
      display: none;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    ul {

      li {
        margin-bottom: 3px;
      }

    }

    .key {
      white-space: nowrap;
    }

    .sketch-picker{
      position: fixed;
      z-index: 10;
      right: 10px;
      top: 45%;

      input{
        color: $black;
      }
    }

    .color-picker{
      .color {
        width: 15px;
        height: 10px;
        border: 1px solid #b5b5b5;
      }

      .swatch {
        background: '#fff';
        display: inline-block;
        cursor: pointer;
      }

      .popover {
        position: absolute;
        z-index: 2;
      }

      .cover {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .options-type{
      ul{
        padding-bottom: 10px;
        li{
          color: $white;
          position: relative;
          padding-left: 14px;
          display: inline-block;
          margin-right: 15px;

          &:before{
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: #7E8083;
            border-radius: 50%;
            left: 0;
            top: 4px;
          }

          &.active{
            &:before{
              background-color: #50E9BA;
            }
          }
        }
      }
    }

  }

  .note {
    color: $gray;
    padding: 10px;
    text-align: center;
  }

  .pseudostate-select {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .pseudostate-select > button {
    border: 1px solid #333;
    width: 46%;
    height: 50px;
    font-weight: $font-bold;
    font-size: 14px;
    margin: 3px;

    &.selected {
      background-color: $green;
      color: $black;
    }
  }

}
