.modal-backdrop {
  background-color: darken($card-background, 4%);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.modal-header {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 24px;

  button span {
    padding-left: 5px;
  }
}

.modal-body {
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  &.popup{
    display: block;
    text-align: center;
    margin:0 auto;
  }
}

.popup-body {
  display: block;

}

.modal-footer {
  padding: 24px 0 0;
}

.modal-button {
  font-size: 14px;
  background-color: transparent;
  color: $white;
  border-radius: $border-radius;
  padding: 15px 20px;
  transition: all 0.5s;
  margin-right: 15px;
  border: 1px solid #797c80;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: #50e9ba;
    color: $card-background;
  }

  &:disabled {
    background-color: $gray;
    opacity: 0.5;
  }
}
