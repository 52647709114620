// Color Bars for Edition Details Header
header { // Scope

  .colorbar-checkedouttoyou {
    animation-duration: 1s;
    animation-name: colorbar-animate;
    background: $edm-colorbar-checkedouttoyou;
  }

  .colorbar-checkedouttoother {
    animation-duration: 1s;
    animation-name: colorbar-animate;
    background: $edm-colorbar-checkedouttoother;
  }

  .colorbar-open {
    animation-duration: 1s;
    animation-name: colorbar-animate;
    background: $edm-colorbar-open;
  }

  .colorbar-archived {
    animation-duration: 1s;
    animation-name: colorbar-animate;
    background: $edm-colorbar-archived;
  }
}
