// Edition Details Header
//
// The Header block for the edition details page.
//
// Markup:
// <demo-wrapper-1210>
//  <editiondetails-container>
// <header class="editiondetails">
//   <div class="breadcrumb"><span class="home">❮ Home</span></div>
//   <div class="edition-status">Checked Out to You</div>
//   <div class="edition-checkin"><button>Check In</button></div>
//   <div class="edition-colorbar colorbar-checkedouttoyou"></div>
// </header>
//  </editiondetails-container>
// </demo-wrapper-1210>
//
// Styleguide 6.5.1


// TODO remove in favor of app/scripts/panels/EditionManager/header.scss
header { // Sets Scope

  // Homepage Specific Header elements
  .edition-colorbar {
    border-radius: 4px;
    display: block;
    float: left;
    height: 4px;
    margin-top: 10px;
    width: 100%;
  }

  .edition-status {
    display: block;
    float: left;
    font-size: 14pt;
    font-weight: 300;
  }

  .edition-checkin {
    display: block;
    float: right;
    margin-top: -10px;

    button {
      @include editionman-button;;

      &:disabled {
        background: $edm-coolgrey;
        border: $edm-coolgrey2;
        color: $edm-coolgrey2;
        cursor: not-allowed;
        opacity: .5;


        &:hover {
          background: $edm-coolgrey;
          border: $edm-coolgrey2;
          color: $edm-coolgrey2;
          cursor: not-allowed;
        }
      }
    }

  }

  .breadcrumb {
    color: $edm-lightgrey;
    cursor: pointer;
    display: block;
    float: left;
    margin-bottom: 40px;
    width: 100%;

    span {
      font-size: 11pt;
    }

    .home {

      &:hover {
        color: $edm-white;
        text-decoration: underline;
      }
    }
  }
}
