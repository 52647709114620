@import './vendor/black-tie';

@mixin solid  { @extend .bts; }
@mixin bold   { @extend .btb; }
@mixin medium { @extend .btm; }
@mixin light  { @extend .btl; }
@mixin spin   { @extend .bt-spinner; }
@mixin fixed  { @extend .bt-fw; }
@mixin small  { @extend .bt-sm; }
@mixin large  { @extend .bt-lg; }

.icon {
  color: $gray;
  &.variable { @include medium;; @include fixed;; @extend .bt-question-circle; }
  &.ban { @include solid;; @include fixed;; @extend .bt-ban; color: #BBB; }
  &.camera { @include solid;; @include fixed;; @extend .bt-eye; }
  &.new-edition { @include solid;; @include fixed;; @extend .bt-file; }
  &.open-edition { @include solid;; @include fixed;; @extend .bt-hard-drive; }
  &.save-edition { @include solid;; @include fixed;; @extend .bt-cloud-upload; }
  &.preview-edition { @include solid;; @include fixed;; @extend .bt-play-circle; }
  &.edition {@include medium;; @extend .bt-folder; }
  &.chapter {@include medium;; @extend .bt-folder; }
  &.sequence {@include medium;; @extend .bt-maximize; }
  &.expand { @include medium;; @include fixed;; @extend .bt-angle-up; }
  &.collapse { @include bold;; @include fixed;; @extend .bt-angle-down; }
  &.selector { @include bold;; @include large;; @extend .bt-ulist; }
  &.variables { @include solid;; @include large;; @extend .bt-label; }
  &.media {@include medium;; @include large;; @extend .bt-film; }
  &.add { @include bold;; @extend .bt-plus; }
  &.add-circle { @include solid;; @include small;; @extend .bt-plus-circle; }
  &.remove { @include solid;; @extend .bt-minus-circle; }
  &.times { @include medium;; @include small;; @extend .bt-times; }
  &.edit { @include solid;; @extend .bt-pencil; }
  &.image { @include solid;; @include fixed;; @extend .bt-photo; }
  &.container { @include medium;; @extend .bt-folder; }
  &.sprite { @include solid;; @extend .bt-photo; }
  &.input { @include medium;; @extend .bt-comment; }
  &.text { @include medium;; @extend .bt-text-size; }
  &.paragraph { @include medium;; @extend .bt-text-size; }
  &.film { @include medium;; @extend .bt-film; }
  &.sort-asc { @include medium;; @include fixed;; @extend .bt-angle-up; }
  &.sort-desc { @include medium;; @include fixed;; @extend .bt-angle-down; }
  &.media-mp3 { @include solid;; @include fixed;; @extend .bt-music; }
  &.media-mp4 { @include solid;; @include fixed;; @extend .bt-film; }
  &.media-png { @include solid;; @include fixed;; @extend .bt-photo; }
  &.media-svg { @include solid;; @include fixed;; @extend .bt-photo; }
  &.media-jpg { @include solid;; @include fixed;; @extend .bt-photo; }
  &.media-jpeg { @include solid;; @include fixed;; @extend .bt-photo; }
  &.rewind { @include solid;; @include fixed;; @extend .bt-fast-reverse; }
  &.previousFrame { @include solid;; @include fixed;; @extend .bt-previous; }
  &.nextFrame { @include solid;; @include fixed;; @extend .bt-next; }
  &.play { @include solid;; @include fixed;; @extend .bt-play; }
  &.pause { @include solid;; @include fixed;; @extend .bt-pause; }
  &.clock { @include medium;; @include fixed;; @extend .bt-clock; }
  &.up-arrow { @include solid;; @extend .bt-circle-arrow-up; }
  &.down-arrow { @include solid;; @extend .bt-circle-arrow-down; }
  &.debug { @include bold;; @include fixed;; @extend .bt-maximize; }
  &.chevron {@include bold;; @include fixed;; @extend .bt-angle-down; transition: all 0.2s; }
  &.chevron-expanded { transform: rotate(0deg); }
  &.chevron-collapsed { transform: rotate(180deg);}
  &.dropdown { @include bold;; @include small;; @extend .bt-angle-down; }
  &.right-arrow { @include solid;; @include small;; @extend .bt-caret-right; }
  &.stage { @include solid;; @include fixed;; @extend .bt-folder; }
  &.info { @include solid;; @extend .bt-info-circle; }
  &.file { @include solid;; @extend .bt-file; }
  &.search { @include medium;; @extend .bt-search; }
  &.loading { @include bold;; @extend .bt-spinner; @extend .bt-pulse; }
  &.question { @include solid;; @extend .bt-question-circle; @include small;; }
}
