.stage {
  .asset {
    // Buttons
    &.button {
      transition: box-shadow 0.3s ease !important;
      &:focus {
        outline: 2px solid #82BCFB !important;
        outline-offset: 2px !important;
      }
    }
    // Text Inputs
    &.input {
      transition: box-shadow 0.3s ease !important;
      &::placeholder {
        color: rgba(69, 105, 137, 0.6);
      }
      &:focus {
        outline: 2px solid #82BCFB !important;
        outline-offset: 2px !important;
      }
      &:disabled {
        &::placeholder {
          color: rgba(137, 137, 137, 0.6);
        }
      }
    }
    // Checkboxes
    &.checkbox {
      input:focus + .focus-indicator {
        outline: 2px solid #82BCFB !important;
        outline-offset: 2px !important;
      }
      &.disabled {
        // pseudo checkmark
        span {
          opacity: .5;
        }
      }
    }
    // Radios
    &.radio {
      input:focus + .focus-indicator {
        outline: 2px solid #82BCFB !important;
        outline-offset: 2px !important;
      }
      &.disabled {
        // pseudo dot
        span {
          opacity: .5;
        }
      }
    }
  }
}
