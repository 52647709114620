#import {

  height: 100%;
  padding: 10px;
  width: 100%;

  .header {
    min-height: 48px;
    padding-bottom: 20px;
    position: relative;
    width: 250px;

    h1 {
      opacity: 1;
      transition: opacity .2s;
    }
  }

  .progress-bar {
    margin-top: 10px;
  }

  .steps {
    display: block;
    float: left;

    .step {
      color: $gray;
      display: block;
      font-size: 14px;
      margin-bottom: 30px;
      width: 100%;

      .upload-success {
        color: $white;

        h5 {
          padding-top: 15px;
        }
      }

      .upload-failed {
        color: $interfacepink;

        h5 {
          padding-top: 15px;
        }
      }

      select {
        -moz-appearance: menulist;
        -webkit-appearance: menulist;
        appearance: menulist;
        border: 1px solid $gray;
        border-radius: 8px;
        display: block;
        height: 30px;
        padding: 5px 10px;
        width: auto;
      }

      .step-text {
        font-weight: 500;
        margin-bottom: 15px;
      }

      .clear-button {
        padding-left: 15px;

        &:hover {
          text-decoration: underline;
        }

        &.hidden {
          display: none;
        }

      }

      button {
        &.choose {
          border: 1px solid $white;
          border-radius: $border-radius;
          color: $white;
          margin-bottom: 20px;
          padding: 10px;
          width: 130px;

          &:hover {
            background: $white;
            color: $black;

            &:disabled {
              background: none;
              color: $white;
            }
          }

          &:disabled {
            opacity: .2;

          }
        }
      }


      input {

        &[type='file'] {
          display: none;
        }

        &[type='text'] {
          border-bottom: 1px solid $gray;
          margin-bottom: 10px;
          width: 200px;
        }
      }

      .file-name {
        color: $interfacepink;

        i {
          display: none;
        }

        &.has-file {
          color: $white;

          i {
            display: inline-block;
            margin-right: 5px;
          }
        }
      }


      .file-list {
        max-height: 210px;
        overflow-x: hidden;
        overflow-y: scroll;
        width: 150px;

        li {
          font-weight: 500;
          padding: 3px 10px;

          i {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .errors {
    @extend .disable-selection;
    float: left;
    margin-left: 50px;

    .hidden {
      display: none;
    }

    .visible {
      display: block;
    }

    ul {
      border: 1px solid $border;
      border-radius: $border-radius;
      max-height: 645px;
      max-width: 500px;
      overflow-x: auto;
      overflow-y: scroll;
      padding: 5px 10px;
      transition: all .2s;
      width: 400px;

      &:empty {
        display: none;
      }
    }

    li {
      align-items: flex-start;
      color: $gray;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 3px 0;
      transition: color .2s;

      &.message {
        @extend .truncate;
        color: $gray;
        display: block;
        font-family: 'Roboto Mono';
        font-size: 12px;
        height: 20px;
        overflow: visible;

      }
    }

  }

  .conversion-button {
    background-color: $selection;
    border-radius: $border-radius;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    margin-right: 15px;
    padding: 15px 20px;
    transition: all .5s;

    &:hover {
      background: darken($selection, 10%);
    }

    &:disabled {
      background-color: $gray;
      opacity: .5;
    }
  }

  .cancel-button {
    font-size: 14px;
    font-weight: 500;
  }

  .actions {
    flex: 0 1 auto;
    text-align: center;
  }

}
