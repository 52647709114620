/* Generic Colors */

$white: #fff;
$black: #000;
// New Colors for Text
$newtextgray: #b9bbc0;
$gray: $newtextgray;
$dark-gray: rgba(122, 135, 145, 0.20);
$light-gray: #CCCCCC;
$red: #F54C7F;
$green: #50E9BA;
$green30: rgba(80,233,186, 0.3);
$yellow: #FFC003;
$selection: $green;
$selected: $selection;
$sequence: #03c4ff;
$chapter: #445ABA;
$node: #F5A623;
$edition: darken($chapter, 20%);
$error: $red;

$black70percent: rgba(0, 0, 0, .7);  // Modal Overlay color
$black30percent: rgba(0, 0, 0, .3);
$black50percent: rgba(0, 0, 0, .5);
$black35percent: rgba(0, 0, 0, .35);
$black20percent: rgba(0, 0, 0, .2);
$interfacegrey: #7a8791; // Primary Text color - Modals
$interfacewhite: #fff; // White - Button color and hover color for interface icons
$interfacedarkgrey: #1c1f21; // background color for selection dropdowns
$interfacegreen: #50e9ba; // Hover color for button elements
$interfacepink: #ef4771; // Validation Text
$interfaceblack: #000; // Button black
$interfacegrey: #797c80;
$verylightgrey: #ecefef; // For use in sub-menu backgrounds
/* Structural Colors */

$background: rgba(39, 42, 46, 1);

/* Card Colors */

$card-title: rgba(0, 0, 0, .95);
$card-background: rgba(25, 28, 31, 0.8);
$card-border: 1px solid rgba(250, 250, 250, 0.07);

$section-background: #24282B;

/* Panel-specific Colors */

$takeover-background: #1E2124;
$takeover-border: #242a31;
$widgets-background: #24292d;
$viewport-background: #272A2E;
$panel-background: #1e2224;
$timeline-background: #24282B;
$timeline-easing-options: #ecefef;
$timeline-control-seperator: #4f5253;
$widget-hover: #c0c4c9;
$media-preview-watermark: #353638;
$sub-section-header: $viewport-background;
/* Toolbar Colors */

$toolbar: rgba(25,28,30,1);
$progress-bar: #4D5156;
$seperator-bar: #303439;
$mid-menu-seperator: #979797;

$border: #282828;
$default-border: #aaa;
$focused-border: #ccc;
$changed-border: #fff;

$highlight: #6a6a6a;
$button: #d8d8d8;
$active: #475abc;
$brand: #0092c6;
