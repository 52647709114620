$connection-bg: rgba(31,36,41,1);
$connection-else-bg: rgba(31,36,41,.7);
$connection-border: #2b2f35;

.connection {
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 5px;

  &.default {

    .index {
        background: none;
    }

    .statement {

        &.else {
          background: none;
        }
    }
  }

  &:hover {

    .statement {
      border-color: $connection-border;

      &.else {
        border-color: transparent;
      }

      .remove-connection {
        opacity: 1;
      }

    }

  }

  .form-row {

    &.flex-start {
      align-items: flex-start;
    }

    &.center {
      align-items: center;
    }

  }

  .index {
    background: $connection-border;
    border-radius: $border-radius;
    border: 2px solid transparent;
    color: $gray;
    margin-right: 5px;
    padding: 5px 1px;
  }

  .statement {
    background: $connection-bg;
    padding: 10px;
    border: 2px solid transparent;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.35), 0 1px 4px 0 rgba(0,0,0,.25);
    border-radius: $border-radius;

    &.else {
      background: $connection-else-bg;
      // box-shadow: none;
    }

    .form-row {
      flex-direction: column;
      align-items: flex-start;

      &.inline {
        flex-direction: row;
        align-items: center;
      }

      .key {
        width: 100%;
      }

      .value {
        width: 100%;
        flex: 1 0 auto;

        &.radio {

          margin-bottom: 6px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          label {
            flex: 1 0 auto;
          }

        }

        &.select {
          margin-bottom: 6px;
        }
      }

    }

    .remove-connection,
    .collapse-connection {
      position: absolute;
      top: 4px;
      right: 0;
      padding: 0;
      font-size: 10px;
      opacity: 0;
      transition: opacity 0.2s;
      color: #55595d;
    }

    .collapse-connection {
      opacity: 1;

      &.collapsed {

      }

    }

  }

}
