// Edition Details Summary
//
// The Header block for the edition details page.
//
// Markup:
// <demo-wrapper-1210>
//  <editiondetails-container>
// <section class="edition-summary">
//   <table class="edition-summary-table">
//     <tr>
//       <td>
//         <span class="edition-current-name">Bacon Edition</span>
//         <span><!-- react-text: 910 -->Latest Version <!-- /react-text --><!-- react-text: 911 -->0.0.1<!-- /react-text --></span>
//       </td>
//       <td>
//         <span><!-- react-text: 912 -->Latest Version Created: <!-- /react-text --><!-- react-text: 913 -->09/25/17<!-- /react-text --></span>
//         <span><!-- react-text: 914 -->By: <!-- /react-text --><!-- react-text: 915 -->asilin<!-- /react-text --></span>
//       </td>
//       <td>
//         <span><!-- react-text: 916 -->Created: <!-- /react-text --><!-- react-text: 917 -->10/27/16<!-- /react-text --></span>
//         <span><!-- react-text: 918 -->By: <!-- /react-text --><!-- react-text: 919 -->ktwery<!-- /react-text --></span>
//       </td>
//       </tr>
//       <tr>
//       <td>
//         <span>Asset Set</span>
//         <span class="assetset"><strong>dd</strong><!-- react-text: 921 --> - standard english | deb doetzer | female<!-- /react-text --></span>
//       </td>
//       <td>
//         <button class="continue-editing">Continue Editing</button>
//       </td>
//     </tr>
//   </table>
// </section>
//  </editiondetails-container>
// </demo-wrapper-1210>
//
// Styleguide 6.5.2


.edition-summary {
  margin-bottom: 10px;

  .edition-summary-table {
    color: $edm-lightgrey;
    display: block;
    float: left;
    font-size: 11pt;
    padding: 2%;
    width: 96%;

    .edition-current-name {
      color: $edm-white;
      font-weight: 700;
    }

    tr {

      &:nth-child(1) { // First Row

        td {
          line-height: 1.4em;
          vertical-align: top;


          &:nth-child(1) { //First Column - Title and Current Version
            border-right: 1px solid $edm-lightgrey;
            width: 350px;
          }

          &:nth-child(2) { //Second Column - Latest Version created
            padding-left: 70px;
            width: 255px;
          }

          &:nth-child(3) {//Third Column - Originally created
            padding-left: 50px;
            width: 255px;
          }
        }
      }

      &:nth-child(2) { // Second Row

        td {

          &:nth-child(1) {
            padding-top: 30px;
          }
        }
      }

      td {

        button {
          @include editionman-button;;

          &:disabled {
            background: $edm-coolgrey;
            border: $edm-coolgrey2 1px solid;
            color: $edm-coolgrey2;
            cursor: not-allowed;
            opacity: .5;
          }

          &.continue-editing {
            margin: 29px 0 0 71px;
          }
        }


        select {
          @include editionmanager-select;;
        }

        span {
          color: $edm-lightgrey;
          display: block;
          float: left;
          margin-bottom: 5px;
          width: 100%;
        }
      }
    }

    .assetset {
      text-transform: capitalize;

      strong {
        text-transform: uppercase;
      }
    }
  }
}
