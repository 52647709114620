$tree-font-size: 12px;
$tree-font-height: 1.2;
$tree-leading: 5px;
$tree-connect-color: rgba(255, 255, 255, 0.5);
$tree-connect-color-invisible: rgba(255, 255, 255, 0);
$tree-connect-width: 8px;
$tree-connect-gap: 7px;
$tree-indent: $tree-connect-width + $tree-connect-gap;
$tree-icon-width: 10px;

@mixin hide-connection() {
  border-left-color: $tree-connect-color-invisible !important;
  &:after {
    border-left-color: $tree-connect-color-invisible !important;
    border-bottom-color: $tree-connect-color-invisible !important;
  }
}

.tree {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: $tree-font-size;
}
.tree .tree {
  margin-left: calc($tree-indent + ($tree-icon-width / 2));
}
.tree .tree-item {
  @extend .disable-selection;
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  line-height: normal;
  color: white;
  border-left: 1px solid $tree-connect-color;
}
.tree .tree-item:last-child {
  border-left: none;
}
.tree .tree-item:after {
  position: absolute;
  height: calc(($tree-font-size * $tree-font-height / 2) + $tree-leading + 1px); // 1 is for the bottom border on title input/span
  width: $tree-connect-width;
  color: white;
  border-bottom: 1px solid $tree-connect-color;
  content: '';
  display: inline-block;
  left: 0;
  top: 0;
}
.tree .tree-item:last-child:after {
  border-left: 1px solid $tree-connect-color;
}

.tree .tree-item .tree-item-inner {
  // This allows the drag background to exclude the connection line, but the margin screws up our flexbox positioning
  padding-left: $tree-connect-gap + $tree-connect-width;
}

// Remove connection from root
.tree.is-root > .tree-item {
  @include hide-connection();;
  &:last-child:after {
    border-left-color: $tree-connect-color-invisible !important;
    border-bottom-color: $tree-connect-color-invisible !important;
  }
  > .tree {
    margin-left: 0;
    // Hide the connections for first 2 levels
    > .tree-item {
      @include hide-connection();;
    }
  }
  > .tree-item-inner {
    padding-left: 0;
    .sprite.hierarchy {
      margin-right: $tree-connect-gap - 1;
    }
  }
}

// Hide the connections for chapters (necessary for chapter outline)
.tree-item-chapter {
  @include hide-connection();;
}

.tree-item {
  .collapser {
    position: absolute;
    top: 4px;
    left: -2px;
    margin-left: -5px;
    z-index: 10;
    button.chevron {
      padding: 5px;
    }
    .sprite.chevron {
      display: block;
      background-color: $white;
      transition: all 0.3s ease;
      &.collapsed {
        transform: rotate(90deg) scale(0.8);
      }
      &.expanded {
        transform: rotate(180deg) scale(0.8);
      }
    }
  }
}

.tree-item-inner {
  display: flex;
  width: 100%;
  align-items: center;
  padding: $tree-leading 0;
  cursor: pointer;
  .sprite.hierarchy {
    display: block;
    margin-right: $tree-connect-gap;
    min-width: $tree-icon-width;
    vertical-align: top;
  }
  .tree-item-input,
  .tree-item-title {
    display: block;
    flex: auto 1 1;
    overflow-x: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    strong {
      color: $interfacegreen;
    }
  }
  // Match up span and input styles as close as possible to prevent "jumping"
  .tree-item-title {
    border-bottom: 1px solid transparent;
  }
  .tree-item-input {
    padding-left: 0;
    margin-bottom: 0;
  }
  .actions {
    opacity: 0;
    transition: opacity 0.2s ease;
    flex: auto 0 1;
    align-self: flex-end;
    button.edit {
      padding: 0 0 0 4px;
      i {
        padding-right: 0;
      }
    }
  }
  &:hover {
    .actions {
      opacity: 1;
    }
  }
  &.selected .sprite.hierarchy,
  &.selected .tree-item-title {
    color: $selection;
  }
  .dragging & {
    opacity: .25;
  }
  &.editing .actions {
    display: none;
  }
}

// i.e. Container inside of a Container
.tree.collapsible > .tree-item {
  & > .collapser {
    left: $tree-indent;
  }
  & > .collapser + .tree-item-inner {
    padding-left: calc(($tree-indent * 2) + ($tree-icon-width / 2));
  }
}

// Layout Drag and drop indicators
.tree-item {
  .drop-below {
    display: block;
    height: 3px;
    width: 100%;
    &:hover{
      background: none;
    }
    &.dragging {
      background: none;
      opacity: 1;
      &.over{
        background: $green;
        border-top: 1px solid $panel-background;
        border-bottom: 1px solid $panel-background;
      }
    }
  }
}
// Structure Hierarchy doesn't have the green background hovers..
.sequence-hierarchy .tree-item.over {
  > .tree-item-inner {
    background: $green30;
  }
}

// Structure Drag and drop indicator
.tree-item {
  .drop-target {
    display: none;
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background: $selection;
    left: 0;
  }
}
.tree.is-root > .tree-item {
  & > .drop-target {
    left: 0;
  }
}

.dragging-chapter {
  // Chapter over Edition
  .tree-item-edition.over > .drop-target { bottom: 0; left: 16px; display: block; }
  // Chapter over Chapter
  .tree-item-chapter.over > .drop-target { top: 0; left: 0; display: block; }
  // Chapter over Sequence
  .tree-item-sequence > .tree-item-inner { opacity: .25; }
  // Chapter over DecisionNode
  .tree-item-decisionnode > .tree-item-inner { opacity: .25; }
}

.dragging-sequence {
  // Sequence over Edition
  .tree-item-edition > .tree-item-inner { opacity: .25; }
  // Sequence over Chapter
  .tree-item-chapter.over > .drop-target { bottom: 0; left: 16px; display: block; }
  // Sequence over Sequence
  .tree-item-sequence.over > .drop-target { top: 0; left: 0; display: block; }
  // Sequence over DecisionNode
  .tree-item-decisionnode > .tree-item-inner { opacity: .25; }
}

.dragging-decisionnode {
  // DecisionNode over Edition
  .tree-item-edition.over > .drop-target { bottom: 0; left: 16px; display: block; }
  // DecisionNode over Chapter
  .tree-item-chapter.over > .drop-target { bottom: 0; left: 16px; display: block; }
  // DecisionNode over Sequence
  .tree-item-sequence > .tree-item-inner { opacity: .25; }
  // DecisionNode over DecisionNode
  .tree-item-decisionnode.over > .drop-target { top: 0; left: 0; display: block; }
}
