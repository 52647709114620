#widgets {

  h2 {
    font-size: 12px;
    color: $white;
  }

  .tip {
    border: 1px solid #333;
    padding: 5px;
    margin: 10px 0 0 0;
    color: #444;
  }

  background: transparent;

}

.asset-grid {
  display: flex;
  flex-wrap: wrap;
  background: transparent;
  justify-content: center;

  .asset {
    cursor: grab;
    flex: 0 1 60px;
    padding: 7px 5px 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;

    label {
      font-size: 11px;
      color: $gray;
      cursor: grab;
    }

    &:hover {

      .sprite {
        background: $widget-hover;
      }

      label{
        color: $widget-hover;
      }
    }
  }

}

.sprite.widget {
  margin: 10px 0 5px;
}

.asset-icon {
  background-image:url('../images/widget-sprites.png');
  background-size: 350px 150px;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 0 auto;

  &.container {
    background-position: -250px 0;
  }

  &.image {
    background-position: -300px 0;
  }

  &.sprite {
    background-position: -300px 0;
  }

  &.text {
    background-position: -250px -50px;
  }

  &.input {
    background-position: -150px -50px;
  }

  &.button {
    background-position: -50px -50px;
  }

  &.checkbox {
    background-position: 0 -50px;
  }

  &.radio {
    background-position: -200px -50px;
  }

}
