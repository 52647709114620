.timeline-controls {
  align-items: center;
  color: $button;
  display: flex;
  flex: 1 0 $control-height;
  justify-content: center;
  padding-left: 160px; // Offset the easing controls
  position: relative;
  width: 100%;

  button {
    background: transparent;
    border: 0;
    margin-right: 6px;
    padding: 0;
    vertical-align: middle;

    &:first-of-type {
      margin-left: 6px;
    }

  }

  .controls-timecode,
  .controls-timecode-frame {
    color: $white;
    font-size: 12px;
    font-weight: 100;
    width: 150px;

    label {
      color: $control-gray;
      margin-right: 6px;
    }

    .slash {
      margin: 0 5px;
      color: $control-gray;
    }

    input {
      width: auto;
      min-height: 0;
      text-align: center;
    }

  }

  .controls-timecode-frame {
    display: flex;
    align-items: center;
    .input-wrap {
      flex: 0 1 auto;
    }
  }

  .controls-timecode { text-align: right; }

  .controls-keyframe-ease {
    color: $white;
    font-size: 12px;
    font-weight: 100;
    width: 150px;
    display: flex;

    > label {
      flex: 1 0 auto;
      margin-right: 10px;
    }

    div.Select {
    }


  }

}

.control {
  background-image: url('../images/timeline-sprites.svg');
  background-repeat: no-repeat;
  background-size: 191px 102px;
  display: block;
  height: 16px;
  width: 16px;

  &.go-to-beginning {
    background-position: 0 0;
    background-size: 240px 129px;
    height: 21px;
    margin-top: -3px;
    width: 20px;

    &:hover {
      background-position: -21px 0;
    }

  }

  &.rewind {
    background-position: -34px 0;

    &:hover {
      background-position: -51px 0;
    }

  }

  &.play {
    background-position: -68px 0;
    height: 30px;
    width: 30px;

    &:hover {
      background-position: -99px 0;
    }

  }

  &.pause {
    background-position: -68px -31px;
    height: 30px;
    width: 30px;

    &:hover {
      background-position: -99px -31px;
    }

  }

  &.animate {
    background-position: -68px -62px;
    height: 30px;
    width: 30px;

    &:hover {
      background-position: -99px -62px;
    }

  }

  &.fast-forward {
    background-position: -34px -17px;

    &:hover {
      background-position: -51px -17px;
    }

  }

  &.go-to-end {
    background-position: 0 -21px;
    background-size: 240px 129px;
    height: 21px;
    margin-top: -3px;
    width: 20px;

    &:hover {
      background-position: -21px -21px;
    }

  }
}

.animate-mode {

  .control {

    &.go-to-beginning {
      background-position: 0 -43px;
      background-size: 240px 129px;
      height: 21px;
      margin-top: -3px;
      width: 20px;

      &:hover {
        background-position: -21px -43px;
      }

    }

    &.rewind {
      background-position: -34px -34px;

      &:hover {
        background-position: -51px -34px;
      }

    }

    &.play {
      background-position: -130px 0;
      height: 30px;
      width: 30px;

      &:hover {
        background-position: -161px 0;
      }

    }

    &.pause {
      background-position: -130px -31px;
      height: 30px;
      width: 30px;

      &:hover {
        background-position: -161px -31px;
      }

    }

    &.animate {
      background-position: -130px -62px;
      height: 30px;
      width: 30px;

      &:hover {
        background-position: -161px -62px;
      }

    }

    &.fast-forward {
      background-position: -34px -51px;

      &:hover {
        background-position: -51px -51px;
      }

    }

    &.go-to-end {
      background-position: 0 -64px;
      background-size: 240px 129px;
      height: 21px;
      margin-top: -3px;
      width: 20px;

      &:hover {
        background-position: -21px -64px;
      }

    }
  }

  .controls-timecode,
  .controls-timecode-frame {
    color: $black;

    label {
      color: $black;
    }

    .slash {
      color: $black;
    }

    input {
      color: $black;
      border-color: $gray;

      &:focus {
        &:hover {
          border-color: $gray;
        }
      }
    }

  }


}
