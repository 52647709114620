// Warning Modal
//
// Modal appears in several locations to provide warning notification on a pending change. Intended to replace browser driven alert behaviors.
// Markup:
// <div class="interfacegrey" style="padding:50px; width:700px; height:350px;" >
//    <div class="modal-wrap">
//       <div class="modal-content" style="width: 600px; height: auto;">
//        <header class="modal-header"><h1></h1><button>Close</button></header>
//        <div class="modal-body popup">
//          <div class="warning-wrap">
//            <label>Checking in an edition will delete any unversioned saves.</label>
//            <label>Continue?</label>
//            <div class="buttons">
//              <button class="left-option">Ok</button><button class="right-option">Cancel</button>
//            </div>
//          </div>
//        </div>
//      </div>
//    </div>
// </div>
//
// Styleguide 2.2.3

.warning-wrap {
  color: $edm-lightgrey;
  font-family: 'Roboto';
  font-size: 16px;
  padding: 20px 0;
  text-align: left;
  width: 500px;

  label {
    float: left;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    width: 100%;
  }

  .buttons {
    display: block;
    float: left;
    width: 100%;

    .left-option {
      float: left;
    }

    .right-option {
      float: right;
    }

  }

  button {
    border: 1px solid $interfacewhite ;
    border-radius: 4px;
    color: $interfacewhite ;
    display: block;
    font-weight: 500;
    margin: 20px auto;
    padding: 11px 45px;
    text-align: center;

    &:hover {
      background: $interfacegreen;
      border: 1px solid $interfacegrey;
      color: $interfaceblack;
    }
  }
}
