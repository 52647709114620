#toolbar {
  background: $toolbar;
  height: 44px;
  position: absolute;
  z-index: 100;
  width: 100%;

  .toolbar-inner {
    align-items: center;
    display: flex;
    height: 44px;
  }

  .brand {
    margin: 14px 0 10px 10px;
    width: 110px;
    height: 30px;
  }

  .file-menu {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    user-select: none;
    flex: 1 0 auto;
    font-weight: 400;
    font-size: 14px;

    >.active{
        color: $black !important;
        background-color: $interfacegreen;
    }
    > .menu-item {
      color: $gray;
      padding: 0 12px;
      cursor: pointer;
      line-height: 44px;
      position: relative;

      .sub-menu {
        min-width: 200px;
        padding: 5px 0;
      }

      .show-menu {
        display: flex !important;
      }

      .hide-menu {
        display: none;
      }

    }

    .menu-divider {
      width: 100%;
      height: 0;
      border-top: 1px solid $mid-menu-seperator;
      margin: 5px 0;
      opacity: .32;
    }

    .sub-menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      padding: 0;
      box-shadow: 0 0 30px $black30percent, 0 15px 12px $black20percent; // inset 0 0 0 2px rgba(255, 255, 255, 1)
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
      background-color: $verylightgrey;
      border: 2px solid $interfacewhite;

      .menu-item {
        min-width: 160px;
        padding: 5px 10px;
        line-height: 1;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $black;

        &.active{
          background: #ffffff;
        }

        .shortcut {
          color: $black;
        }

        a {
          text-decoration: none;
          display: block;
          color: $black;
        }

        &:hover {
          background-color: $interfacegreen;
          color: $black;

          > .shortcut,
          > a {
            color: $black;
          }
        }

        &.disabled {
          color: rgba($gray, 0.5);

          .shortcut {
            color: rgba($gray, 0.5);
          }

          &:hover {
            background-color: transparent;
          }
        }

        &.hidden {
          display: none;
        }
      }

      .sub-menu {
        top: -5px;
        left: 100%;
        border-radius: $border-radius;
      }
    }
  }

  div.edition {
    font-size: 14px;
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    height: 44px;
    font-weight: 400;

    .edition-icon{
      background: url('../images/edition-icon.svg') no-repeat;
      background-size: 12px 12px;
      display: block;
      height: 12px;
      margin: 2px 0 -18px -20px;
      width: 12px;
    }

    .save-state {
      position: relative;
      border-left: 1px solid $seperator-bar;
      height: 44px;
      padding: 13px 0 0 16px;

      @keyframes indicator-success {
        0%   { transform: scale(0); opacity: 1; }
        10%  { transform: scale(0); opacity: 1; }
        20%  { transform: scale(1); opacity: 1; }
        60%  { opacity: 1; }
        100% { opacity: 0; }
      }

      @keyframes indicator-error {
        0%   { transform: scale(0); }
        10%  { transform: scale(0); }
        60%  { transform: scale(1); }
        100% { transform: scale(1); }
      }

      .indicator {
        border-radius: 50%;
        height: 8px;
        left: 9px;
        opacity: 0;
        padding: 0;
        position: absolute;
        top: 11px;
        width: 8px;
        z-index: 1;

        &.success {
          animation: indicator-success 3.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) 1;
          background-color: $green;
        }

        &.error {
          animation: indicator-error 3.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) 1;
          background-color: $error;
          opacity: 1;
        }
      }

      .sprite {
        padding: 0;
        position: relative;
        left: 0px;
        margin-right: 10px;
      }

      button {
        padding: 0;
      }
    }

    .edition-name {
      color: $white;
      margin: 0 10px 0 15px;
      cursor: default;
      padding-right: 15px;
      flex: 1 1 auto;

      i {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        -webkit-mask-size: 16px 16px;
      }
    }
    .widgets-button {
      color: $white;
      margin: 0 0 0 15px;
      cursor: default;
      padding: 12px 15px;
      border-left: 1px solid $seperator-bar;
      flex: 1 1 auto;
      height: 44px;

      i {
        background: $white;
      }

      &:hover,
      &.active {
        background: $interfacegreen;
        color: $black;

        i {
          background: $black;
        }
      }
      .widgets-menu{
        margin-left: 10px;
        vertical-align: middle;
        display: inline-block;
        -webkit-mask: url('../images/widgets-icon.svg');
        -webkit-mask-size: 20px 20px;
        -webkit-mask-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }

      label{
        vertical-align: middle;
      }
    }
  }
}

.toolbar-alerts {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;
}

.toolbar-alert {
  display: flex;
  background-color: $white;
  box-shadow: 0 0 6px rgba(0,0,0,1);
  color: $black;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  margin: 0 auto;
  width: 25%;
  justify-content: space-between;

  position: absolute;
  left: 50%;
  transition: opacity 300ms ease-in, transform 300ms ease-in-out;

  &.error {
    background-color: $red;
    color: $white;
  }

  &.warn {
    background-color: #F5A623;
    color: $black;
  }

  &.success {
    background-color: $interfacegreen;
    color: $black;
  }

  @for $i from 0 through 9 {
    $top: (55px * $i);
    $zIndex: 9 - $i;
    &.toolbar-alert-#{$i} {
      z-index: $zIndex;
      transform: translate(-50%, $top);
      &.flyIn-enter-active {
        transform: translate(-50%, $top);
      }
    }
  }

  &.flyIn-enter {
    opacity: 0;
    transform: translate(-50%, -200%);
    transition-duration: 300ms, 300ms;
  }
  &.flyIn-enter-active {
    opacity: 1;
  }
  &.flyIn-exit {
    opacity: 1;
    transition-duration: 240ms, 240ms;
  }
  &.flyIn-exit-active {
    opacity: 0;
    transform: translate(-50%, -200%);
  }

  .alert-message {
    padding: 18px 25px;
  }
  .alert-dismiss {
    display: flex;
    align-items: center;
    width: 5%;
    color: $black;
  }
}
