// Override SASS Styles to accomidate legacy Jellyfish style issues




editionmanager {

  overflow-y: scroll;

  h5 {
    margin: 16px;
  }

  select {
    border: 1px solid $edm-white;
    color: $edm-white;
    height: auto;
    line-height: 1.2em;
    margin-bottom: 0;
    margin-top: 0;
    width: auto;

    &:hover {
      height: auto;
      line-height: 1.2em;
      margin-bottom: 0;
      margin-top: 0;
      width: auto;
    }
  }
}


.recent-editions {

  p,
  span {
    color: $edm-white;
  }
}

.recent-edition-toggle {
  box-sizing: content-box;
  font-size: 16px;
  font-weight: 500;
}

.edition-search {

  .search-clear {
    margin-top: -2px;
  }
}

.search-bar-input {

  &:hover,
  &:focus {
    border: 0 !important;
  }
}
