.drop-element,
.drop-element:after,
.drop-element:before,
.drop-element *,
.drop-element *:after,
.drop-element *:before {
  box-sizing: border-box;
}

.drop-element {
  position: absolute;
  display: none;
  z-index: 11;
}
.drop-element.drop-open {
  display: block;
}

.drop-element.drop-theme-basic {
  max-width: 100%;
  max-height: 100%;
}

.drop-element.drop-theme-basic .drop-content {
  border-radius: $border-radius;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  font-family: inherit;
  background: #eee;
  color: #444;
}

.drop-content {

  ol.property-menu {
    padding: 5px 0;

    li.property-wrapper {
      padding: 2px 10px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;

      > .icon {
        width: 11px;
        margin-right: 5px;
      }

      &.active {

        > .icon > i {
          background-color: $green;
        }

      }

      &:hover {
        background-color: $selection;
        color: $white;

        &.active {

          > .icon > i {
            background-color: $white;
          }

        }


      }

    }

  }

}
