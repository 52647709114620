#blender {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;

  .header {
    border-bottom: 1px solid $takeover-border;
    margin-bottom: 20px;
    min-height: 48px;
    padding-bottom: 20px;
    position: relative;
    width: 250px;


    h1 {
      opacity: 1;
      transition: opacity 0.2s;
    }

  }

  .steps {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;

    .step {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      max-width: 33.3%;
      padding: 0 5px;

      h2 {
        align-items: center;
        display: flex;
        font-size: 16px;
        margin: 10px 0;
        min-height: 24px;

        .step-number {
          background-color: $white;
          border-radius: 12px;
          border: 1px solid $white;
          color: $black;
          display: inline-block;
          font-weight: $font-bold;
          height: 24px;
          line-height: 24px;
          margin-right: 5px;
          text-align: center;
          vertical-align: center;
          width: 24px;
        }

      }

      .wrapper {
        @extend .disable-selection;
        overflow-y: scroll;
        overflow-x: hidden;
        flex: 1 0 0;
        width: 100%;
        border-radius: $border-radius;
        padding-left: 30px;

        &.bordered {
          border: 1px solid $border;
          transition: all 0.2s;
          padding: 5px 10px;
        }

        .button-wrapper {

        }

        .over {
          border-bottom: 2px solid $sequence;
          padding-bottom: 5px;
          margin-bottom: 5px;
        }

        li {

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 3px 0;
          color: $gray;
          transition: color 0.2s;
          font-weight: 500;
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;

          > ul {
            width: 100%;
          }

          &.divider {
            border-bottom: 1px solid $gray;
            padding: 0;
            margin: 10px 0;
          }

          &.empty {
            text-align: left;
            font-size: 14px;
            color: darken($gray, 10%);
          }

          &.message {
            color: $gray;
            font-family: 'Roboto Mono';
            font-size: 10px;
            @extend .truncate;
            display: block;
          }

          &:hover {
            color: $white;
          }

          &.selected {
            color: $selection;
          }

          &.edition-hierarchy {

            &.addition {

              span {
                color: $light-gray;
              }

              i {
                background-color: $light-gray;
              }

            }


            span {
              color: $gray;
            }

            i {
              background-color: $gray;
              min-width: 10px;
              margin-right: 5px;
            }

            &.chapter {
              margin-left: 20px;
            }

            &.sequence {
              margin-left: 20px;
            }

            &.selected,
            &.selected span {
              color: $selection;
            }

            &.selected i {
              background-color: $selection;
            }

            &.blended-selected,
            &.blended-selected:hover,
            &.blended-selected span,
            &.blended-selected span:hover {
              color: $sequence;
            }

            &.blended-selected i,
            &.blended-selected i:hover {
              background-color: $sequence;
            }

            button.remove-button {
              padding: 0;
              margin: 0 0 0 5px;
              opacity: 0.5;
              transition: opacity 0.2s;
              cursor: pointer;

              i {
                background-color: transparent;
                padding-right: 0;
                min-width: 9px;
                margin: 0;
              }

              &:hover {
                opacity: 1;
              }

            }

          }

        }

      }

      div.wrapper {
        display: flex;
        flex: none;
        margin: 10px 0 25px 0;
        padding-left: 0;
        flex-direction: column;

        .button-wrapper {
          display: flex;
          flex-direction: row;

          button {
            flex: 1 0 0;
            border: 1px solid $white;
            border-radius: $border-radius;
            color: $white;
            padding: 8px;
            font-size: 14px;

            &:first-child {
              margin-right: 10px;
            }

            &:disabled {
              opacity: 0.5;
            }

            &.active {
              border-color: $selection;
              color: $selection;
            }

          }

        }

      }

      .item-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
      }

      .edition-name {
        padding: 25px 0 0;
        display: flex;
        align-items: center;

        i.check {
          background-color: $selection;
          margin-right: 5px;
        }

        span {
          color: $selection;
          margin: 0 10px 0 0;
          cursor: default;
        }

        input[type="text"] {
          color: $selection;
          margin: 0 10px 0 0;
          border-bottom: 1px solid transparent;

          &::placeholder {
            color: $selection;
          }

          &:hover {
            border-bottom-color: rgba($selection, 0.5);
          }

          &:focus {
            border-bottom-color: $selection;
          }

        }

        button {
          padding: 0;
          opacity: 0.25;
          transition: opacity 0.2s;

          &:hover {
            opacity: 1;
          }

        }

      }

    }

  }

  .actions {
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .cancel-button {
      font-size: 14px;
      font-weight: 500;
    }

  }

}

.blend-button {
  font-size: 14px;
  background-color: $error;
  color: $white;
  border-radius: $border-radius;
  padding: 15px 0px;
  transition: all 0.5s;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  display: block;
  margin-top: 10px;

  &:hover {
    background: darken($error, 10%);
  }

  &:disabled {
    background-color: $gray;
    opacity: 0.5;
  }
}
