// Edition Search Results
//
// Returned table of search results from Homepage Edition Search.
//
// Markup:
// <demo-wrapper-1210>
//  <homepage-container>
// <section class="search-results">
//   <table class="editionsearch">
//     <tr>
//       <th class="filter"><strong>Edition Name</strong><i class="chevron-xtrasmall desc"></i></th>
//       <th class="filter"><strong>Status</strong><i class="chevron-xtrasmall desc"></i></th>
//       <th class="filter active"><strong>Updated</strong><i class="chevron-xtrasmall desc"></i></th>
//       <th><strong>Last Author</strong></th><th><strong>Version Notes</strong></th>
//     </tr>
//     </table>
//     <div class="search-results-body overflow">
//       <table class="search-results-table editionsearch">
//         <tr>
//           <td title="Bacon Edition">Bacon Edition</td>
//           <td class="checked-out" title="by nobody">Checked Out</td>
//           <td title="at 10:57am">09/25/17</td>
//           <td title="">asilin</td>
//           <td title="Initial version, auto generated">Initial version, auto generated</td>
//         </tr>
//         <tr>
//           <td title="Bacon Edition (Copy)">Bacon Edition (Copy)</td>
//           <td class="">Open</td>
//           <td title="at 06:28am">07/10/17</td>
//           <td title="">asilin</td>
//           <td title="Initial version, auto generated">Initial version, auto generated</td>
//         </tr>
//       </table>
//     <div class="search-load-more" id="search-load-more"><em>Showing records 1 - 20 of 42 Search Results</em></div>
//   </div>
// </section>
//  </homepage-container>
// </demo-wrapper-1210>
//
// Styleguide 6.4.4


// Edition Versions Results
//
// Returned table of edition versions and history on Edition Details page.
//
// Markup:
// <demo-wrapper-1210>
//  <homepage-container>
// <section class="search-results">
//   <table class="editionhistory">
//     <tr>
//       <th>Edition Name</th>
//       <th>Version</th>
//       <th>Created</th>
//       <th>Created By</th>
//       <th>Version Notes</th>
//     </tr>
//   </table>
//   <div class="search-results-body overflow">
//     <table class="search-results-table editionhistory">
//       <tr>
//         <td>
//           <span class="version-name" title="Bacon Edition">Bacon Edition</span>
//           <div class="version-actions">
//             <button class="copy" title="Create Copy" data-id="6cb97e68-f5c0-4536-8ca8-89bc6288253b" data-version="0.0.1"></button>
//             <button class="promote" title="Promote Version" data-id="6cb97e68-f5c0-4536-8ca8-89bc6288253b" data-version="0.0.1"></button>
//           </div>
//         </td>
//         <td>0.0.1</td>
//         <td title="at 10:57am">09/25/17</td>
//         <td title="">ktwery</td>
//         <td title="Initial version, auto generated">Initial version, auto generated</td>
//       </tr>
//     </table>
//   </div>
// </section>
//  </homepage-container>
// </demo-wrapper-1210>
//
// Styleguide 6.5.4





.search-results { // Sets Scope

  &.hide {
    animation: hidesearch .25s;
    opacity: 0;

  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;

    tr {
      th {
        cursor: default;
        font-size: 11px;
        height: 20px;
        overflow: hidden;
        padding: 20px 0;
        text-align: left;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
        min-width: 75px;

        &.filter {
          cursor: pointer;

          strong,
          i {
            display: block;
            float: left;
          }

          .chevron-xtrasmall {
            margin-left: 10px;

            &.asc {
              margin-top: 3px;
            }

            &.desc {
              margin-top: 2px;
            }
          }
        }

        &:nth-child(1) {
          padding: 0 20px;
          width: 300px;
        }

        &:nth-child(2) {
          width: 120px;
        }

        &:nth-child(3) {
          width: 100px;
        }

        &:nth-child(4) {
          width: 100px;
        }

        &:nth-child(5) {
          max-width: 500px;
          padding: 0 20px;
        }
      }

      td {
        border-bottom: 1px solid $edm-darkgrey;
        border-top: 1px solid $edm-darkgrey;
        cursor: default;
        font-size: 14px;
        height: 20px;
        overflow: hidden;
        padding: 20px 0;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:nth-child(1) {
          border-left: 1px solid $edm-darkgrey;

        }

        &:nth-child(5) {
           border-right: 1px solid $edm-darkgrey;

        }
      }

      &:hover {

        // Hover border styles
        td {
          border-bottom: 1px solid $edm-mintgreen;
          border-top: 1px solid $edm-mintgreen;
          padding: 20px 0;

          &:nth-child(1) {
            border-left: 1px solid $edm-mintgreen;

          }

          &:nth-child(5) {
            border-right: 1px solid $edm-mintgreen;

          }
        }
      }

      // Zebra Striping
      &:nth-child(2n) {
        background: $edm-grey;
      }
    }

    &.editionsearch {  // modifier class for table layout

      td {

        &:nth-child(1) {
          color: $edm-lightgrey;
          padding: 0 20px;
          width: 299px;

          .edition-title {
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 250px;
          }

          &:hover {
            color: $edm-white;
            cursor: pointer;
          }
        }

        &:nth-child(2) {
          color: $edm-lightgrey;
          width: 120px;

          &.checked-out {
            color: $edm-magenta;
          }

          &.archived {
            color: $edm-gold;
          }
        }

        &:nth-child(3) {
          color: $edm-coolgrey2;
          width: 100px;
        }

        &:nth-child(4) {
          color: $edm-lightgrey;
          width: 100px;
        }

        &:nth-child(5) {
          color: $edm-lightgrey;
          max-width: 380px;
          padding: 0 20px;
        }
      }
    }
    &.editionadvisorlink {
      td,th {
        &:nth-child(5) {
          color: $edm-lightgrey;
          width: 200px;
          min-width:130px;
          padding: 0 20px;
        }
        &:nth-child(6) {
          color: $edm-lightgrey;
          width: 200px;
          min-width:130px;
          padding: 0 20px;
        }
        &:nth-child(7) {
          color: $edm-lightgrey;
          width: 200px;
          min-width:120px;
          padding: 0 20px;
        }
        &:nth-child(8) {
          color: $edm-lightgrey;
          width: 200px;
          min-width:160px;
          padding: 0 20px;
        }
      }
      tr{
        td{
          &:nth-child(5) {
            border-right:none;
          }
      
      
        }
        }
      tr:hover{
        td{
            &:nth-child(5) {
             border-right:none; //0px solid $edm-mintgreen;

            }
            &:nth-child(8) {
              border-right: 1px solid $edm-mintgreen;

            }
        }
      }
    }

    &.editionhistory {  // modifier class for table layout

      tr {

        .version-actions {
          display: block;
          float: right;
          width: 80px;

          button {
            border: 0;
            display: block;
            float: left;
            height: 16px;
            opacity: .2;
            width: 24px;

            &.copy {
              background: url('../images/editionmanager/copy.svg') no-repeat;
              background-size: 24px 16px;
            }
            &.promote {
              background: url('../images/editionmanager/promote.svg') no-repeat;
              background-size: 24px 16px;

              &:disabled {
                cursor: not-allowed;
              }

            }
          }
        }

        &:hover {

          .version-actions {

            button {
              float: left;
              opacity: .7;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }

      td {
        .version-name {
          color: $edm-lightgrey;
          display: inline-block;
          float: left;
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 170px;
        }
        .link-icon-container{
            padding-left:10px;
            &:hover{
                  color: white;
            }
        }
        .comment-icon-container{
            padding-left:15px;
            &:hover{
              color: white;
            }
        }
        .link-expiration{
          width: 80px;
          &.expired{
            color: #6F6F6F;
          }
          &.invalid{
            color: #F54C7F;
          }
        }

        .expiration {
          &:hover {
            .expiration-discard {
              opacity: .7;
            }
          }
        }
        .expiration-discard {
          float: right;
          margin-right: 10px;
          opacity: 0;
          width: 20px;
    
          &:hover {
            cursor: pointer;
            opacity: 1;
         }
    
        }

      

        &:nth-child(1) {
          color: $edm-lightgrey;
          padding: 0 20px;
          width: 300px;

          &:hover {
            color: $edm-white;
            cursor: pointer;
          }
        }

        &:nth-child(2) {
          color: $edm-coolgrey2;
          width: 120px;
        }

        &:nth-child(3) {
          color: $edm-lightgrey;
          width: 100px;
        }

        &:nth-child(4) {
          color: $edm-lightgrey;
          width: 100px;
        }

        &:nth-child(5) {
          color: $edm-lightgrey;
          max-width: 380px;
          padding: 0 20px;
        }
      }
    }
  }

  // Search Results Loading mechanic
  .search-load-more,
  .search-load-nomore {
    background: $edm-darkgrey;
    display: block;
    padding: 5px 0;
    text-align: center;
    width: 99.8%;
  }
}

// Edition Search Results Loading
//
// Loading Animation for pending search results.
//
// Markup:
// <demo-wrapper-1210>
//  <homepage-container>
//    <section class="search-results-loading">
//      <img src="../static_ui/images/jellyfish-icon.png"> Searching...
//    </section>
//  </homepage-container>
// </demo-wrapper-1210>
//
// Styleguide 6.4.5

// Edition Version List loading
//
// Loading Animation for pending edition version listing.
//
// Markup:
// <demo-wrapper-1210>
//  <homepage-container>
//    <section class="search-results-loading">
//      <img src="../static_ui/images/jellyfish-icon.png"> Searching...
//    </section>
//  </homepage-container>
// </demo-wrapper-1210>
//
// Styleguide 6.5.5

// Scope Exception - Loading Animation
.search-results-loading {
  animation: pulse-loading .5s infinite;
  height: 0;
  margin-bottom: -100px;
  overflow: visible;
  position: relative;
  text-align: center;

  &.hide {
    display: none;

  }
}
