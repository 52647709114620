
.index-controls {
  background: $panel-background;
  border-bottom: 1px solid $interfacegrey;
  display: block;
  padding: 7px 0 7px 5px;
  position: absolute;
  top: 25px;
  width: 218px;
  z-index: 100;

  i,
  span {
    display: block;
    float: left;
  }

  .current-sequence {
    display: block;
    float: left;
    padding-top: 3px;

    .sequence-icon {
      background: $gray;
      display: inline-block;
      height: 18px;
      margin-right: 5px;
      -webkit-mask: url('../images/index-control/layout-search.svg') no-repeat;
      -webkit-mask-position: -11px 0;
      -webkit-mask-size: 74px;
      width: 15px;
    }

    .current-sequence-name {
      color: $gray;
      display: inline-block;
      height: 20px;
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }


  .switch-controls {
    display: block;
    float: right;
    padding-right: 5px;

    .previous {
      background: $interfacegrey;
      cursor: pointer;
      display: inline-block;
      height: 18px;
      -webkit-mask: url('../images/index-control/layout-search.svg') no-repeat;
      -webkit-mask-position: -58px 0;
      -webkit-mask-size: 100px;
      width: 20px;

      &:hover {
        background: $interfacewhite;
      }
    }

    .next {
      background: $interfacegrey;
      cursor: pointer;
      display: inline-block;
      height: 18px;
      -webkit-mask: url('../images/index-control/layout-search.svg') no-repeat;
      -webkit-mask-position: -34px 0;
      -webkit-mask-size: 100px;
      width: 20px;

      &:hover {
        background: $interfacewhite;
      }
    }
  }
}
