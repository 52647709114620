// Edition Manager Style Variables
//
// Style Variables for use within the Edition Manager. These
// variables are scoped only for use in the edition manager section
// and Jellyfish and should not be used in other parts of the app.
//
// Styleguide 6.1


// Primary Colors:
//
// Colors used throughout the Edition Manager. These color variables are local to the Edition Manager and should not be used in other parts of the site.
// Markup:
// <div class="colorswatch edm-darkgrey-bg">$edm-darkgrey : #1c2022</div>
// <div class="colorswatch edm-coolgrey-bg">edm-coolgrey : #24292d</div>
// <div class="colorswatch edm-grey-bg">$edm-grey : #24292c</div>
// <div class="colorswatch edm-lightgrey-bg">$edm-lightgrey : #797c80</div>
// <div class="colorswatch edm-coolgrey2-bg">$edm-coolgrey2 : #6d7b87</div>
// <div class="colorswatch edm-white-bg">$edm-white : #fdfdfd</div>
// <div class="colorswatch edm-magenta-bg">$edm-magenta : #d62b59</div>
// <div class="colorswatch edm-gold-bg">$edm-gold : #f7af3a</div>
// <div class="colorswatch edm-black-bg">$edm-black : #000</div>
// <div class="colorswatch edm-mintgreen-bg">$edm-mintgreen : #50e9ba</div>
//
// Styleguide 6.1.1

$edm-darkgrey: #1c2022;  // Background Color, darker zebra stripe in search results and version history
$edm-coolgrey: #24292d; // edition summary and recent editions panel
$edm-grey: #24292c; // lighter zebra stripe in search results and version history tables
$edm-lightgrey: #b9bbc0; // primary text color
$edm-coolgrey2: #6d7b87; // Secondary text color
$edm-white: #fdfdfd; // header text and button text
$edm-magenta: #d62b59; // Checked Out
$edm-gold: #f7af3a; // Archived
$edm-black: #000; // Black - Used for drop down backgrounds.
$edm-mintgreen: #50e9ba; // Bright mint green. used for button hover states

// Scrollbar Colors
//
// Used to style the scrollbar elements within the Edition Manager
//
// Markup:
// <div class="colorswatch edm-scrollbar-shadow">$edm-scrollbar-shadow : #555</div>
// <div class="colorswatch edm-scrollbar-bar">edm-scrollbar-bar : #f5f5f5</div>
// <div class="colorswatch edm-scrollbar-bg">$edm-scrollbar-bg : rgba(0, 0, 0, .3);</div>
//
// Styleguide 6.1.2

$edm-scrollbar-background: #555;
$edm-scrollbar-bar: #f5f5f5;
$edm-scrollbar-shadow: rgba(0, 0, 0, .3);

// Colorbars
//
// Gradient colors for colorbars used on the Edition Details page
//
// Markup:
// <div class="colorswatch-bar edm-colorbar-checkedouttoyou"><strong>Check Out to You</strong> - linear-gradient(90deg, $edm-colorbar-green 0%, $edm-colorbar-blue 65%)</div>
// <div class="colorswatch-bar edm-colorbar-checkedouttoother"><strong>Checked Out to Other</strong> - linear-gradient(90deg, $edm-colorbar-red 0%, $edm-colorbar-blue 65%)</div>
// <div class="colorswatch-bar edm-colorbar-open"><strong>Open</strong> - linear-gradient(90deg, $edm-colorbar-lightblue 0%, $edm-colorbar-blue 65%)</div>
// <div class="colorswatch-bar edm-colorbar-archived"><strong>Archived</strong> - linear-gradient(90deg, $edm-colorbar-gold 0%, $edm-colorbar-blue 65%)</div>
//
// Styleguide 6.1.3

$edm-colorbar-blue: #475abc;
$edm-colorbar-green: #36e2b2;
$edm-colorbar-red: #e6396d;
$edm-colorbar-gold: #f7b03b;
$edm-colorbar-lightblue: #02c4ff;
// Colorbar Patterns
$edm-colorbar-checkedouttoyou: linear-gradient(90deg, $edm-colorbar-green 0%, $edm-colorbar-blue 65%);
$edm-colorbar-checkedouttoother: linear-gradient(90deg, $edm-colorbar-red 0%, $edm-colorbar-blue 65%);
$edm-colorbar-open: linear-gradient(90deg, $edm-colorbar-lightblue 0%, $edm-colorbar-blue 65%);
$edm-colorbar-archived: linear-gradient(90deg, $edm-colorbar-gold 0%, $edm-colorbar-blue 65%);

// Font Face
//
// Primary applciation font is Roboto, with Arial and sans-serif as redundant fonts.
//
// $edm-fonts: 'Roboto', Arial, sans-serif
//
// Styleguide 6.1.4

$edm-fonts: 'Roboto', Arial, sans-serif;
