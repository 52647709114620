.variables {

  ul {

    li {
      display: flex;
      color: $gray;
      padding: 2px 5px 2px 10px;

      &:hover {

        .var-remove {
          opacity: 0.25;
        }

      }

      &:nth-child(even) {
        background-color: rgba($gray, 0.05)
      }

      &.empty {
        padding: 10px;
        display: block;
        background: transparent;
      }

      &.header {

        color: darken($gray, 20%);


        label {
          font-size: 10px;
          line-height: 12px;
        }

        .var-value {
          flex: 1 1 0;
        }

      }

      .var-name {
        @extend .truncate;
        flex: 1 1 0;
        margin-right: 5px;
      }

      .var-value {
        width: 100% !important;
        @extend .truncate;
        &:focus {
          text-overflow: clip;
        }
      }
      
      select.var-value {
        color: $white;
        padding-left: 0;
        height: 18px;
        &:hover {
          appearance: none;
          margin: 0;
        }
      }

      .input-wrap,
      .select-wrap {
        flex: 1 1 0;
      }

      .var-remove {
        opacity: 0;
        transition: opacity 0.2s;
        width: 12px;
        margin-left: 5px;
      }

    }

  }

  .var-form {
    margin: 15px 10px 10px;
    border: 1px solid $border;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0 5px 10px;
    flex-wrap: wrap;

    input {
      padding: 0;
      width: 100px;
    }

    button {
      flex: 1 0 0;
    }
    
    .validation {
      padding: 4px;
      span {
        color: #ef4771;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
      }
      .hidden {
        display: none;
      }
    }

  }

}
