/* Flexbox Utilities */

.flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.fill {
  flex: 1 0 0;
}

.fit {
  flex: 0 1 0;
}

.flex {
  flex: 1 1 0;
}

.expand {
  flex: 1 1 auto;
}

/* Base Application Structure */

#app-outer {
  height: 100%;
  width: 100%;
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}


#app-inner {
  display: block;
  height: 100%;
  width: 100%;
  animation: fade-in 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) 1;

  #interface {
    display: block;
    float:left;
    overflow: hidden;

    &.column {
      float:left;
    }


    .row {
      position: relative;
      display: block;
      float:left;
      flex-direction: row;
      overflow: hidden;
    }

    .script {
      order: 2
    }

    .column {
      display: block;
      margin: 2px;

      &.paired {
        min-width: 240px;
      }

      > .row {
        display:block;
        float:left;
        height: 100%;
        width: 100%;

        + .row { margin-top: 4px; }
      }
    }

    .tabs {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      min-height: 34px;
      flex: 0 1 34px;

      .tab {
        @extend .truncate;
        text-align: center;
        flex: 1 0 0;
        color: $gray;
        font-size: 10px;
        text-transform: capitalize;
        padding: 10px 2.5px;
        background: $card-background;
        font-weight: $font-medium;

        &.active {
          color: $white;
          background: $card-background;
          font-size: 12px;
        }

      }

    }

  }

}
