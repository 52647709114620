@mixin hierarchy {
  -webkit-mask: url('../images/object-sprites-2.svg');
  -webkit-mask-size: 290px 14px;
  -webkit-mask-repeat: no-repeat;
  height: 14px;
  width: 10px;
  background-color: $gray;
}

@mixin widget {
  -webkit-mask: url('../images/object-sprites-2.svg');
  -webkit-mask-size: 442px 21px;
  -webkit-mask-repeat: no-repeat;
  height: 21px;
  width: 15px;
} 

@mixin media {
  background: url('../images/media-sprites.svg');
  background-size: 126px 53px;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}

@mixin raw-media {
  -webkit-mask: url('../images/media-sprites.svg');
  -webkit-mask-size: 126px 53px;
  -webkit-mask-repeat: no-repeat;
  width: 12px;
  height: 12px;
}

@mixin toolbar {
  background: url('../images/toolbar-sprites.svg');
  background-size: 68px 20px;
  background-repeat: no-repeat;
  transition: opacity 0.2s;
}

@mixin menu {
  -webkit-mask: url('../images/toolbar-sprites.svg');
  -webkit-mask-size: 224px 19px;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: -159px 0;
  width: 21px;
  height: 17px;
}

@mixin timeline {
  background: url('../images/timeline-sprites.svg');
  background-size: 191px 102px;
  background-repeat: no-repeat;
}

@mixin timeline-mask {
  -webkit-mask: url('../images/timeline-sprites.svg');
  -webkit-mask-size: 191px 102px;
  -webkit-mask-repeat: no-repeat;
}

@mixin script {
  background: url('../images/script-sprites.svg');
  background-size: 154px 30px;
  background-repeat: no-repeat;
}

@mixin script-mask {
  -webkit-mask: url('../images/script-sprites.svg');
  -webkit-mask-size: 154px 30px;
  -webkit-mask-repeat: no-repeat;
}

@mixin conversion {
  background: url('../images/conversion-sprites.svg');
  background-size: 61px 20px;
  background-repeat: no-repeat;
}

@mixin detail {
  -webkit-mask: url('../images/detail-sprites.svg');
  -webkit-mask-size: 12px 8px;
  -webkit-mask-repeat: no-repeat;
}

@mixin general {
  background: url('../images/general-sprites.svg');
  background-size: 175px 125px;
  background-repeat: no-repeat;
}


@mixin button       { -webkit-mask-position: -101px 0; }
@mixin canvas       { -webkit-mask-position: -120px 0; }
@mixin chapter      { -webkit-mask-position: -11px 0; }
@mixin checkbox     { -webkit-mask-position: -61px 0; }
@mixin container    { -webkit-mask-position: -41px 0; }
@mixin decisionnode { -webkit-mask-position: -31px 0; }
@mixin edition      { -webkit-mask-position: 0 0; }
@mixin input        { -webkit-mask-position: -143px 0; }
@mixin listitem     { -webkit-mask-position: -173px 0; }
@mixin list         { -webkit-mask-position: -112px 0; }
@mixin lock         { -webkit-mask-position: -163px 0; }
@mixin radio        { -webkit-mask-position: -153px 0; }
@mixin sequence     { -webkit-mask-position: -21px 0; }
@mixin text         { -webkit-mask-position: -82px 0; }
@mixin paragraph    { -webkit-mask-position: -278px 0; }
@mixin slider       { -webkit-mask-position: -183px 0; }
@mixin embed        { -webkit-mask-position: -193px 0; }
@mixin stage        { -webkit-mask-position: -204px 0; }
@mixin left         { -webkit-mask-position: -215px 0; }
@mixin right        { -webkit-mask-position: -247px 0; }
@mixin center       { -webkit-mask-position: -231px 0; }
@mixin justify      { -webkit-mask-position: -263px 0; }

.sprite {
  display: inline-block;

  &.widget {
    @include widget;;
    background: $interfacegrey;
    cursor: grab;
    -webkit-mask-position: -139px 0;

    &.button        { -webkit-mask-position: -155px 0; }
    &.canvas        { -webkit-mask-position: -186px 0; }
    &.chapter       { -webkit-mask-position: -16px 0; }
    &.chapter-script       { -webkit-mask-position: -16px 0; margin: -4px 8px 0 0; float:left; background: $white }
    &.checkbox      { -webkit-mask-position: -94px 0; }
    &.container     { -webkit-mask-position: -62px 0; }
    &.decision-node { -webkit-mask-position: -47px 0; }
    &.input         { -webkit-mask-position: -218px 0; }
    &.listitem      { -webkit-mask-position: -263px 0;}
    &.list          { -webkit-mask-position: -170px 0;}
    &.radio         { -webkit-mask-position: -233px 0; }
    &.sequence      { -webkit-mask-position: -31px 0; }
    &.sequence-script      { -webkit-mask-position: -31px 0; margin: -4px 8px 0 0; float:left; background: $white}
    &.slider        { -webkit-mask-position: -279px 0; }
    &.text          { -webkit-mask-position: -125px 0; }
    &.paragraph     { -webkit-mask-position: -424px 0; }
    &.embed         { -webkit-mask-position: -295px 0; width:16px; }
    &.stage         { -webkit-mask-position: -308px 0; }

    &:hover {
      background: $white;
    }
  }

  &.selection-tools {
    &.vert-dist {
      background: url("../images/vertical-spacing.svg");
      background-repeat: no-repeat;
      background-position: left;
      &:active {
        background: url("../images/vertical-spacing-selected.svg");
        background-repeat: no-repeat;
        background-position: left;
      }
    }
    &.hori-dist {
      background: url("../images/horizontal-spacing.svg");
      background-repeat: no-repeat;
      background-position: left;
      &:active {
        background: url("../images/horizontal-spacing-selected.svg");
        background-repeat: no-repeat;
        background-position: left;
      }
    }
    &.vert-spacer {
      background: url("../images/vert-spacer.svg");
      background-repeat: no-repeat;
      background-position: left;
    }
    &.hori-align-min {
      background: url("../images/horizontal-align-min.svg");
      background-repeat: no-repeat;
      background-position: left;
      &:active {
        background: url("../images/horizontal-align-min-selected.svg");
        background-repeat: no-repeat;
        background-position: left;
      }
    }
    &.hori-align-center {
      background: url("../images/horizontal-align-center.svg");
      background-repeat: no-repeat;
      background-position: left;
      &:active {
        background: url("../images/horizontal-align-center-selected.svg");
        background-repeat: no-repeat;
        background-position: left;
      }
    }
    &.hori-align-max {
      background: url("../images/horizontal-align-max.svg");
      background-repeat: no-repeat;
      background-position: left;
      &:active {
        background: url("../images/horizontal-align-max-selected.svg");
        background-repeat: no-repeat;
        background-position: left;
      }
    }
    &.vert-align-max {
      background: url("../images/vertical-align-max.svg");
      background-repeat: no-repeat;
      background-position: left;
      &:active {
        background: url("../images/vertical-align-max-selected.svg");
        background-repeat: no-repeat;
        background-position: left;
      }
    }
    &.vert-align-center {
      background: url("../images/vertical-align-center.svg");
      background-repeat: no-repeat;
      background-position: left;
      &:active {
        background: url("../images/vertical-align-center-selected.svg");
        background-repeat: no-repeat;
        background-position: left;
      }
    }
    &.vert-align-min {
      background: url("../images/vertical-align-min.svg");
      background-repeat: no-repeat;
      background-position: left;
      &:active {
        background: url("../images/vertical-align-min-selected.svg");
        background-repeat: no-repeat;
        background-position: left;
      }
    }
  }

  &.hierarchy,
  &.action {
    @include hierarchy;;
    -webkit-mask-position: -91px 0;
    &.button        {@include button;;}
    &.canvas        {@include canvas;;}
    &.chapter       {@include chapter;;}
    &.checkbox      {@include checkbox;;}
    &.container     {@include container;;}
    &.decisionnode  {@include decisionnode;;}
    &.edition       {@include edition;;}
    &.input         {@include input;;}
    &.list          {@include list;;}
    &.listitem      {@include listitem;;}
    &.lock          {@include lock;;}
    &.radio         {@include radio;;}
    &.sequence      {@include sequence;;}
    &.slider        {@include slider;;}
    &.text          {@include text;;}
    &.paragraph     {@include paragraph;;}
    &.embed         {@include embed;; width:11px}
    &.stage         {@include stage;;}
    &.left          {@include left;;}
    &.right         {@include right;;}
    &.center        {@include center;;}
    &.justify       {@include justify;;}
  }

  &.details {
    @include hierarchy;;
    -webkit-mask-position: -90px 0;
    &.button {@include button;;}
    &.chapter {@include chapter;;}
    &.checkbox {@include checkbox;;}
    &.container {@include container;;}
    &.decisionnode {@include decisionnode;;}
    &.edition {@include edition;;}
    &.input {@include input;;}
    &.radio {@include radio;;}
    &.sequence {@include sequence;;}
    &.slider {@include slider;;}
    &.stage {@include stage;;}
    &.text {@include text;;}
    &.paragraph {@include paragraph;;}
    &.list {@include list;;}
    &.listitem {@include listitem;;}
    &.embed {@include embed;;}
  }

  &.chevron {
    @include media;;
    background-position: -50px 0;
  }

  &.toolbar {
    @include toolbar;;
    &.edition       { background-position: -78px 0; width: 10px; height: 10px; }
    &.jellyfish     { background-position: 0 0; width: 110px; height: 35px; background-size: 90px 28px; }
    &.save          {
      @include save;;
      background:$white;
      -webkit-mask-size: 10px 10px;
      width: 10px;
      height: 10px;
    }
  }

  &.menu {
    @include menu;;
  }

  &.timeline {
    &.active-on:active  { @include timeline;; background-position: -52px -79px;  width: 12px; height: 12px; }
    &.assets            { @include timeline;; background-position: 0 -94px;      width: 8px;  height: 8px;  }
    &.audio             { @include timeline;; background-position: -9px -79px;   width: 10px; height: 10px; }
    &.canvas            { @include timeline-mask;; -webkit-mask-position: -41px -68px;   width: 10px; height: 10px; }
    &.caret             { @include timeline;; background-position: -20px -79px;  width: 9px;  height: 6px;  }
    &.check             { @include timeline-mask;; -webkit-mask-position: -16px -94px;   width: 11px; height: 8px; }
    &.clone             { @include timeline-mask;; -webkit-mask-position: -53px -68px;   width: 10px; height: 10px; }
    &.display           { @include timeline-mask;; -webkit-mask-position: 0 -68px;       width: 11px; height: 10px; }
    &.expression        { @include timeline-mask;; -webkit-mask-position: -9px -94px;    width: 7px;  height: 8px;  }
    &.hide              { @include timeline-mask;; -webkit-mask-position: -26px -68px;   width: 14px; height: 10px; }
    &.lock              { @include timeline-mask;; -webkit-mask-position: 0 -79px;       width: 8px;  height: 10px; }
    &.mute              { @include timeline-mask;; -webkit-mask-position: -12px -68px;   width: 13px; height: 10px; }
    &.off               { @include timeline;; background-position: -39px -79px;  width: 12px; height: 12px; }
    &.on                { @include timeline;; background-position: -52px -79px;  width: 12px; height: 12px; }
    &.prop              { @include timeline;; background-position: -30px -79px;  width: 8px;  height: 8px;  }
    &.property-menu     { @include timeline-mask;; -webkit-mask-position: 0 -90px;       width: 13px; height: 3px;  }
  }

  &.media {
    @include media;;
    &.add   { background-position: 0 0; width: 12px; }
    &.link  { background-position: -12px 0; }
    &.pause  { background-position: -26px 0; width: 50px; height: 50px; }
    &.play { background-position: -76px 0; width: 50px; height: 50px; }
  }

  &.raw-media {
    @include raw-media;;
    -webkit-mask-position: 0 -14px;
    &.media-mp3 { -webkit-mask-position: -12px -14px; }
    &.media-mp4 { -webkit-mask-position: 0 -26px; }
    &.remove { -webkit-mask-position: -12px -26px; }
  }

  &.script {
    @include script;;
    &.new-chapter { width: 30px; height: 30px; background-position: -48px 0px; }
    &.export { width: 30px; height: 30px; background-position: -78px 0px; }
    &.chevron {
      @include chevron;;
      background:grey;
      //@include script-mask;;
      width: 12px;
      height: 8px;
    }
    &.edit {
      width: 12px;
      height: 13px;
      background-position: -34px 0;
    }
    &.confirm {
      width: 12px;
      height: 10px;
      background-position: -34px -13px;
    }
    &.variables { width: 30px; height: 30px; background-position: -124px 0; }
  }

  &.conversion {
    @include conversion;;
    &.document { width: 9px; height: 12px; }
    &.audio    { width: 9px; height: 9px; background-position: -9px 0; }
    &.remove   { width: 9px; height: 9px; background-position: -18px 0; }
    &.search   { width: 20px; height: 20px; background-position: -27px 0; }
    &.close    { width: 14px; height: 14px; background-position: -47px 0; }
  }

  &.detail {
    @include detail;;
    &.chevron { width: 12px; height: 8px; }
  }

  &.general {
    @include general;;
    &.spinner { width: 125px; height: 125px; background-position: 0 0; }
    &.small-spinner { width: 50px; height: 50px; background-position: -125px 0; }
    &.checkbox { width: 10px; height: 10px; background-position: -154px -115px; }
    &.checkbox.checked { background-position: -165px -115px; }
  }

}

.selected {

  > .sprite {

    &.hierarchy,
    &.action {
      background-color: $selection;
    }

  }

}
