.media-card {
  max-height: 100%;
  width: 99%;

  * {
    @extend .disable-selection;
  }

  .multi-select {
    height: 2em;
    color: #b9bbc0;
    padding: 5px 10px 0 10px;
    display: grid;
    grid-template-columns: 3fr 16px 16px;

    .remove i { 
      background-color: #b9bbc0;
      transition: background-color 0.2s;
    }

    .selected > .count {
      color: #fff;
      font-weight: 900;
    }

    .deselect {
      font-size: 20px;
      font-weight: 700;
      line-height: 0.5;
      button {
        color: #b9bbc0;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 0 10px;
    position: relative;


    .add-media {
      display: block;
      width: 105px;
      padding: 5px;
      margin-right: -12px;
      margin-top:2px;
      order: 2;
      opacity:0.4;
      border-radius:4px;
      &:hover {
        opacity:.8;
      }
    }

    input::placeholder{
      color: #7E8083;
    }

    .media-svg{
      background-color: $gray;
      -webkit-mask-position: -12px -39px;
      -webkit-mask-position: -12px -39px;
      width: 14px;
      height: 14px;
      order: 0;
      position: absolute;
      top: 10px;
      margin-top:2px;
    }

    .input-wrap{
      order: 1;
      margin-left: 18px;
      width: 100%;
      margin-top: 5px;

      .text-field{
        max-width:150px;
        text-overflow:ellipsis;
      }
    }

  }
  .preview {
    border-radius: 6px;
    height: 192px;
    padding: 10px 12px 20px 10px;
    background:$black20percent;
    color:$media-preview-watermark;
    font-weight:900;
    font-size:20px;
    margin: 5px 0 0 5px;

    .body {
      border: 1px solid $border;
      min-height: 100px;
      margin-bottom: 5px;
      overflow: hidden;
      max-height: 109px;
      justify-content: center;
      align-items: center;
    }

    .icon.loading {
      position: absolute;
    }

    canvas {
      outline: 1px dotted $border;
      margin: 0 auto;
    }

    iframe {
      background-color: $white;
      border: none;
      width: 100%;
      height: 100%;
    }

    .audio-control {
      width: 100%;
      height: 100px;
    }

    .meta {
      display: flex;
      align-items: flex-start;

      button.link {
        @extend .fit;
        padding: 5px;
      }

      .meta-form {
        @extend .fill;
        overflow: hidden;

        .ref {
          color: #b9bbc0;
          font-weight: 400;
          &:hover {
            text-decoration: underline;
            color: $white;
          }
        }

      }

      label {
        color: $gray;
        margin-right: 5px;
      }
    }
  }
  .media-preview{
    margin-bottom:10px;
  }
  .search-media{
    border-bottom:1px solid grey;

    min-width:150px;
    padding:2px 0px;
  }

  .media-list {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 0.3s ease;
    
    position: relative;

    &.collapsed{
      max-height: 0;
      padding-bottom: 0;
    }

    &.expanded{
      max-height: 120px;
      padding-bottom: 15px;

      &.no-items{
        max-height: 30px;
      }
    }
    
    &.uploading-in-progress:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $card-background;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    
  }

  .media-list-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 3px 3px 3px 10px;
    cursor: pointer;

    i {
      background-color: $white;
      width: 12px;

      &.selected {
        background-color: $selection;
      }

    }

    .media-name {
      @extend .truncate;
      flex: 1 1 0;
      color: $white;
      margin-left: 5px;

      &.selected {
        color: $selection;
      }

    }

    .media-remove {
      width: 12px;
      height: 12px;
      text-align: right;
      display: none;

      button {
        padding: 0;

        i {
          background-color: $gray;
          transition: background-color 0.2s;
        }

        &:hover {

          i {
            background-color: $white;
          }

        }

      }

    }

    .media-update {
      width: 12px;
      height: 12px;
      text-align: right;
      margin-right: 6px;
      display: none;

      button {
        padding: 0;

        i {
          background-color: $gray;
          transition: background-color 0.2s;
          background-position: 0 -38px;

          &.raw-media{
            -webkit-mask-position: 0 -38px;
            -webkit-mask-position: 0 -38px;
          }
        }

        &:hover {
          i {
            background-color: $white;
          }
        }

      }
    }
    
    .media-loading {
      flex-basis: 9%;
      display: flex;
    }

    &:hover{
      .media-update{
        display: block;
      }
      .media-remove {
        display: block;
      }
    }

  }

}
