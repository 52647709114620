.contextMenu-wrap {
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
}
.contextMenu {
  width: auto;
  height: auto;
  padding: 5px 0;
  box-shadow: 0 0 30px $black30percent, 0 15px 12px $black20percent;
  border-radius: $border-radius;
  background-color: $verylightgrey;
  border: 2px solid $interfacewhite;
}
.contextMenu-option {
  padding: 5px 10px;
  line-height: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $black;
  &:hover {
    background-color: $interfacegreen;
    color: $black;
  }
}
