#viewport {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 1px;
  transform: translateZ(0);

  * {
    @extend .disable-selection;
  }

  &.outline-assets {

    .asset {
      outline: 1px dotted $default-border;

    }

  }

}

.overlay {
  color: $white;

  .link {
    opacity: 0.5;
    transition: opacity 0.125s linear;
    &:after {
      content: "|";
      padding: 0 5px;
    }
    &:hover {
      opacity: 1.0;
      cursor: pointer;
    }
  }
}

.timeline-hidden {
  display: none !important;
  visibility: hidden !important;
}
