// Homepage Recent Editions Section
//
// User selection area of homepage for selecting a project to open from either
// existing unversioned projects or versioned products currently checked out to the user.
//
// Markup:
// <demo-wrapper-1210>
//  <homepage-container>
// <section class="recent-editions expanded" id="recent-editions">
//   <div class="recent-edition-toggle">
//     <span>Your Recent Editions</span><i class="chevron-small"></i>
//   </div>
//   <div class="recent-edition-wrapper">
//     <div class="recent-checked-out">
//       <h5>Checked Out to You</h5>
//       <ul>
//         <li><span class="edition-name" title="Bacon Edition"><!-- react-text: 683 -->Bacon Edition<!-- /react-text --><!-- react-text: 684 --><!-- /react-text --></span><span class="edition-version" title="10:57am">09/25/17</span></li>
//       </ul>
//       </div>
//       <div class="recent-local-saves">
//       <h5>New Editions (Never Versioned)</h5>
//       <ul>
//         <li title="Untitled Edition (Copy)" class="single-edition "><span class="edition-name">Untitled Edition (Copy)</span><span class="edition-lastsave" title="1:19pm">09/19/17</span></li>
//         <li title="Untitled Edition" class="single-edition "><span class="edition-name">Untitled Edition</span><span class="edition-lastsave" title="10:22am">09/01/17</span></li>
//         <li title="Aug31_Ver" class="single-edition "><span class="edition-name">Aug31_Ver</span><span class="edition-lastsave" title="10:19am">09/01/17</span></li>
//       </ul>
//     </div>
//   </div>
// </section>
//  </homepage-container>
// </demo-wrapper-1210>
//
// Styleguide 6.4.2

.recent-editions { // Sets Scope

  .view-all {
    color: $white;
    float: right;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    display: block;
    margin: 0;
    max-height: 120px;
    min-height: 40px;
    padding: 0;
    width: 100%;

    li {
      display: block;
      float: left;
      font-size: 14px;
      list-style: none;
      margin-right: 30px;
      width: 300px;
    }
  }

  &.expanded {
    animation-duration: .5s;
    animation-name: expand-recent;
    height: 270px;
    overflow: hidden;

    .chevron-small {
      transform: rotate(0deg);
    }
  }

  &.collapsed {
    animation-duration: .5s;
    animation-name: collapse-recent;
    height: 63px;
    overflow: hidden;

    .chevron-small {
      transform: rotate(270deg);
    }
  }

  .edition-haslocal {
    color: $edm-lightgrey;
    cursor: pointer;
    display: inline-block;
    float: left;
    margin-right: -10px;
    width: 10px;

  }

  .edition-name {
    color: $edm-lightgrey;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 20px;
    margin-left: 10px;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 170px;


    &:hover {
      color: $edm-white;
      text-decoration: underline;

    }
  }

  .single-edition {

    &:hover {
      .edition-discard {
        opacity: .7;
      }
    }

    .edition-discard {
      float: left;
      margin-right: 10px;
      opacity: 0;
      width: 20px;

      &:hover {
        cursor: pointer;
        opacity: 1;
     }

    }


  }


  .edition-lastsave,
  .edition-version {
    color: $edm-coolgrey2;
    display: inline-block;
    float: left;
    height: 20px;
    width: 50px;
  }

  .recent-edition-toggle {
    cursor: pointer;
    display: block;
    font-size: 21px;
    font-weight: 300;
    height: 20px;
    padding: 20px 2%;
    width: 96%;

    &:hover {
      background: $edm-lightgrey;
    }

    span {
      display: inline-block;
      float: left;
    }

    i {
      display: inline-block;
      float: left;
      margin: 5px 0 0 10px;
    }
  }

  .recent-checked-out {
    display: block;
    float: left;
    margin: 0 30px;
    width: 350px;

    h5 {
      font-size: 13.5px;
      font-weight: 500;
      margin: 20px 0 20px 10px;
      text-transform: uppercase;
    }

    ul {
      height: 150px;
      overflow-y: auto;
    }
  }

  .recent-local-saves {
    display: block;
    float: left;

    h5 {
      font-size: 13.5px;
      font-weight: 500;
      margin: 20px 0;
      text-transform: uppercase;

      span {
        text-transform: none;
      }
    }

    ul {
      border-left: 1px solid $edm-lightgrey;
      height: 120px;
      overflow-y: auto;
      padding-left: 20px;
      width: 700px;

      li {
        float: left;
      }
    }
  }
}
