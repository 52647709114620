.modal-wrap {
  background: $black70percent;
  color: $edm-lightgrey;
  height: 100%;
  width: 100%;
}


.modal-content {
  background-color: $modal-background;
  border: $card-border;
  border-radius: $border-radius;
  box-shadow: 0 0 2px 0 $modal-shadow;
  color: $edm-lightgrey;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column;
  padding: 24px;
}

.modal-header {

  button {
    color: $edm-lightgrey;
  }
}
