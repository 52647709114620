* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.hidden {
  display: none;
}

.uppercased {
  text-transform: uppercase;
}

.disable-selection {
  user-select: none;
}

.relative {
  position: relative;
}

.clearfix:after {
  clear: both;
  content: '';
  display: table;
}

.empty {
  color: #aaa;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
}

.spacer {
  margin-bottom: 14px;
}

::-webkit-scrollbar {
    width: 9px;
}

::-webkit-scrollbar-track {
    border-radius: 4px;
    background: rgba($black, 0);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 3px solid transparent;
    background-color: rgba($gray,0.8);
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:window-inactive {
    border-radius: 10px;
    border: 3px solid transparent;
    background-color: rgba($gray,0.8);
    background-clip: content-box;
}
