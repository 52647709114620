.navigator-wrap {
  display: block;
  margin-top: 40px;
  overflow-x: hidden;
  overflow-y: auto;
}

.index-search {
  border-bottom: 5px solid transparent;
  user-select: none;
}

.hierarchy-section-header {
  background: $sub-section-header;
  color: $interfacewhite;
  display: block;
  clear: both;
  height: 26px;
  padding: 5px 0;
  width: 100%;

  .section-title {
    display: block;
    float: left;
    font-size: 12px;
    font-weight: 500;
    height: 16px;
    margin-left: 6px;
  }

  &.collapsed {

    .toggle {
      margin: 4px 7px 0 -5px;
      transform: rotate(90deg);
      width: 14px;
    }
  }

  .toggle {
    background: $interfacewhite;
    cursor: pointer;
    display: block;
    float: right;
    height: 12px;
    margin-right: 8px;
    -webkit-mask: url('../images/index-control/chevron.svg') no-repeat;
    -webkit-mask-size: 11px;
    transform: rotate(180deg);
    width: 17px;
  }

}

.layout-search-controls {
  display: flex;
  border-bottom: 1px solid $interfacegrey;
  padding: 0px 0 6px;
  margin: 0 0 12px;

  .search-icon {
    background: $gray;
    flex: auto 0 1;
    margin: 1px 6px 0 0;
    -webkit-mask: url('../images/index-control/layout-search.svg') no-repeat;
    -webkit-mask-position: -61px 0;
    -webkit-mask-size: 75px;
    width: 15px;
  }

  .search-input {
    flex: auto 1 1;
    .text-field {
      width: 100% !important;
      &::placeholder {
        color: #666;
      }
    }
  }

  .search-reset {
    flex: auto 0 1;
    color: $gray;
    cursor: pointer;
    font-size: 11px;
    font-weight: 900;
    padding: 0 5px 0 15px;
  }
}

.index-search-results {
  border-bottom: 10px solid transparent;

  .no-results {
    color: $interfacepink;
    display: block;
    font-weight: 500;
    margin-top: 5px;
    text-align: center;
    width: 100%;
  }
}
