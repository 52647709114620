[contenteditable=true]:empty:before{
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: rgba(255, 255, 255, 0.5);
}

/* Font */

$font-families: 'Roboto', Helvetica, Arial, sans-serif;
$font-size: 12px;

/* Font Weight */

$font-thin: 100;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

/* Borders */

$border-radius: 4px;

/* Styles */

body {
  color: $black;
  font-family: $font-families;
  font-size: $font-size;
  font-weight: $font-light;
}

p,
span {
  color: $gray;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $white;
  font-weight: $font-light;
  margin: 0;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
}

button {
  background: transparent;
  border: 0;
  color: $gray;

  &:hover {
    color: $white;

    i, span {
      color: $white;
    }

  }

  i {
    color: $gray;
    padding-right: 4px;
  }

  &.border {
    border: 1px solid darken($gray, 25%);
    border-radius: $border-radius;
    padding: 4px 5px;
  }

  &.no-pad {
    padding: 0;
  }

}

strong {
  font-weight: $font-bold;
}

textarea {
  background: transparent;
  border: 0;
  color: $white;
  border-bottom: 1px solid rgba(0,0,0,0);
  padding: 0;
  resize: none;
  width: 100%;
  display: block;

  &::placeholder {
    font-weight: 300;
    color: $white;
  }

  &:hover {
    border-bottom: 1px solid $white;
  }

  &:focus {
    border-bottom: 2px solid $white;

    &::placeholder {
      font-weight: 300;
      color: $gray;
    }

    &:hover {
      border-bottom: 2px solid $white;
    }

  }

}

label {
  font-weight: $font-regular;
}

pre {
  font-size: 10px;
  color: $white;
  font-weight: $font-regular;
  margin-top: 0;
}

.fake-input {
  margin-bottom: 2px;
  color: $gray;
}

.fake-textarea {
  margin-bottom: 6px;
  display: block;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"],
input[type="number"] {
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,0);
  border-radius: 0;
  display: inline-block;
  color: $white;
  margin-bottom: 1px;
  padding: 0 0 0 1px;
  //max-width: 100%;

  &::placeholder {
    font-weight: 300;
    color: $white;
  }

  &:hover {
    border-bottom: 1px solid $white;
  }

  &:focus {
    border-bottom: 2px solid $white;
    margin-bottom: 0;

    &::placeholder {
      font-weight: 300;
      color: $gray;
    }

    &:hover {
      border-bottom: 2px solid $white;
    }

  }

}

select {
  color: $white;
  font-weight: 300;
  background: transparent;
  appearance: none;
  padding: 0 0 0 8px;
  margin: 0;
  cursor: pointer;
  line-height: 1;
  height: 24px;
  width: 100%;
  border: 0;
  border-radius: 0;

  &:hover {
    -webkit-appearance: menulist-button;
    padding: 0;
    margin: 1px 0 0;
    height: 23px;
    line-height: 1;
  }

  &:disabled {
    color: $gray;
    &:hover {
      -webkit-appearance: none;
      cursor: default;
    }
  }

  &.border {
    padding: 4px 5px;
    border: 1px solid darken($gray, 25%);
    border-radius: $border-radius;
  }
  
  option {
    background: $takeover-background;
  }
}

input[type="color"] {
  border: 1px solid $black;
  padding: 0;
  width: 15px;
  height: 11px;

  &::-webkit-color-swatch {
    border: none;
  }

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 5px;
}

.form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 18px;

  .key {
    @extend .fit;
    color: $gray;
    margin-right: 5px;
    font-weight: 400;
  }

  .value {
    @extend .flex;
    min-width: 1px;
    color: $white;

    &.read-only {
      color: $gray;
    }

  }

  .text-align {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    li {
      button {
        width: 16px;
        height: 12px;
        span {
          background-color: $gray;
          width: 16px;
        }
      }
      &.active{
        button {
          span {
            background-color: $white;
          }
        }
      }
    }
  }

  .sprite {
    flex: 0 0 10px;
    margin-right: 5px;
  }

}

.faux-key {
  border: 1px solid;
  padding: 5px;
  font-weight: $font-light;
  border-radius: 4px;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 4.5px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #333;
  border-radius: 0px;
  border: 0px solid #cccccc;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: none;
  height: 10px;
  width: 5px;
  border-radius: $border-radius;
  background: $gray;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #333;
}
