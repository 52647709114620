/* Convenience Mixins */

$base-gray: #7f7f7f;

@mixin collapsed {font-size: 18px; font-weight: $font-light;}
@mixin expanded {font-size: 12px; font-weight: $font-light;}
@mixin changed {color: rgba(255, 255, 255, 1);}
@mixin unchanged-chapter {color: rgba(255, 255, 255, 0.4);}
@mixin unchanged-sequence {color: rgba(255, 255, 255, 0.6);}
@mixin edition {background: linear-gradient(to bottom, darken(rgba(71,90,188,1), 20%) 0%,darken(rgba(62,89,181,1), 20%) 100%);}
@mixin chapter {background: linear-gradient(to bottom, rgba(71,90,188,1) 0%,rgba(62,89,181,1) 100%);}
@mixin extended-chapter {background: linear-gradient(to right, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 2%), linear-gradient(to bottom, #777 0%, #888 100%)}
@mixin sequence {background: linear-gradient(to bottom, rgba(2,148,245,1) 0%,rgba(3,196,255,1) 100%);}
@mixin extended-sequence {background: linear-gradient(to right, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 2%), linear-gradient(to bottom, #999 0%, #aaa 100%);}
@mixin extended-row {background: linear-gradient(to right, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 2%), linear-gradient(to bottom, #eee 0%, #f5f5f5 100%);}

@mixin chapter-selection {background: linear-gradient(to right, rgba($green,0.75) 0%, rgba($green,0) 50%), linear-gradient(to bottom, rgba(71,90,188,1) 0%,rgba(62,89,181,1) 100%);}
@mixin sequence-selection {background: linear-gradient(to right, rgba($green,0.75) 0%, rgba($green,0) 50%), linear-gradient(to bottom, rgba(2,148,245,1) 0%,rgba(3,196,255,1) 100%);}

.script-bar {
  align-items: center;
  background: linear-gradient(to bottom, rgba(30,33,36,1) 10%, rgba(30,33,36,.6) 100%);
  display: flex;
  height: 50px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 10;

  button {
    margin: 0 5px;
    padding: 0;

    span{
      float: right;
      padding: 9px;
    }

    &:disabled {
      opacity: .25;
      cursor: not-allowed;
    }

    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 1;
    }

    &.active {
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.content.script {
  position: absolute;
  width: 100%;
  display: flex;
}

.script-wrapper {
  display: flex;
  width: 100%;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 50px;
}

/* Lists */

.chapter-list {
  height: 100%;
  flex: 1;
}

.sequence-list,
.row-list {
  margin-top: 8px;
}

.chapter-list:empty,
.sequence-list:empty,
.row-list:empty {
  margin-top: 0;
}


/* Script Objects */

.script-object {
  border-radius: $border-radius;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: block;
  padding: 0 0 6px;
  position: relative;
  font-weight: 500;

  .frame-label {
    color: #999;
    font-size: 10px;
    margin-bottom: 0px;
    @extend .truncate;
  }

  .caption-label {
    color: #999;
    font-size: 10px;
    margin-bottom: 10px;
    @extend .truncate;
  }
  .inner {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .flex{
      padding: 7px 0 0 9px;
    }

    .actions {
  
      align-items: center;
    }

    .script-menu {
      align-self: stretch;
      height:auto;
    }

    &:hover {
      div.script-menu {
        opacity: 0.4;
      }
      button.edit-button {
        opacity: 1;
      }
    }
  }

  .main {
    flex: 1 0 0;
    flex-direction: column;

    .file-list {
      border-top: none;
    }
  }

  .extended-variables {
    display: flex;
    flex-direction: column;
    flex: 1.5 0 0;


    label {
      color: #999;
      font-size: 10px;
      margin-bottom: 5px;
      @extend .truncate;
    }

    .inputs {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      padding: 5px;

      .row-field {
        margin-right: 5px;

        textarea {
          border-color: #ccc;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .empty {
      text-align: center;
      flex: 1 0 auto;

      span {
        color: #bbb;
      }
    }
  }

  .flex {
    display: flex;
    width: 100%;
    align-items: flex-start;
    min-height: 16px;
  }


  .actions {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;

    button.edit-button {
      padding: 0;
      opacity: 0;
      transition: opacity 0.2s;
    }

    button.chevron {
      @include chevron;;
      background:#fff;
      cursor: pointer;
      display: inline-block;
      margin-left:5px;
      padding: 0;
      margin-top:-3px;
      transform: rotate(180deg);

      i.sprite {
      }

      &.collapsed {
        margin-left:0;
        margin-top:0;
        transform: rotate(90deg);
      }
    }

    .lock {
      background-color: #eee;
      margin-top:2px;

      &.locked {
        background-color: #666
      }
    }
  }

  .audio-control {
    width: 16px;
    height: 16px;
    flex: 0 1 auto;
    display: inline-block;
    background-image: url('../images/script-sprites.png');
    background-size: 118px 32px;
    background-position: -18px 0;
    background-repeat: no-repeat;


    &.paused {
      background-position: 0 0;
    }
  }


  .input {
    flex: 1;

    div[contenteditable=false]:empty:before{
      content: attr(placeholder);
      display: block; /* For Firefox */
      color: rgba(255, 255, 255, 0.5);
    }
  }

  /* Lighter input colors */

  input,
  textarea {
    color: $white;

    &:hover,
    &:focus {
      border-color: $white;
    }

    &::-webkit-input-placeholder {
      color: $white;
    }

    &:focus {

      &::-webkit-input-placeholder {
        color: rgba($white, 0.5);
      }
    }
  }
}

/* Specific Script Objects */

/* Chapter */

.script-object.chapter {
  @include chapter;;
  margin-bottom: 5px;
  padding: 4px;

  .chapter-script {
    opacity: .8;
  }


  .content-editable {
    color: $white;

  }

  /* Object is dragged over chapter */

  &.over {
    opacity: 0.5;
  }

  /* Object is selected */

  &.selected {
    @include chapter-selection;;
  }

  /* Highlight color for menu items */

  .popover ol li:hover {
    background-color: $chapter !important;
  }
}

/* Sequence */

.script-object.sequence {
  @include sequence;;
  margin-bottom: 5px;
  padding: 4px;
  transition: border-color 0.2s, opacity 0.2s;

  .sequence-script {
    opacity: .8;
  }
  .content-editable {
    color: $white;
  }

  /* Object is dragged over sequence */

  &.over {
    opacity: 0.5;
  }

  /* Object is selected */

  &.selected {
    @include sequence-selection;;
  }

  /* Highlight color for menu items */

  .popover ol li:hover {
    background-color: $sequence !important;
  }
}

/* Audio Row */

.script-object.audio-row {

  margin-bottom: 5px;
  font-weight: 300;
  background: #212121;
  color: #fff;
  padding: 15px 5px 15px 15px;

  .fake-textarea {
    color: $gray;
  }

  /* Highlight color for menu items */

  .popover ol li:hover {
    background-color: #A4A8AF !important;
  }

  /* Darker menu icon sprite */

  .script-menu {
    margin-right:-5px;
  }

  /* Darker input colors */

  .content-editable {
    color: $gray;
    margin-bottom: 8px;
    border: 1px solid grey;
    padding: 5px;
    border-radius: 3px;
    min-width: 20px;
    margin-top:5px;
    &:focus {
      color: $black;
      background: #dddddd;
    }
  }


  div[contenteditable=true]:empty:before,
  div[contenteditable=false]:empty:before{
    content: attr(placeholder);
    display: block; /* For Firefox */
    color: rgba(gray, 0.5);
  }


  .drop-zone {
    padding: 5px;
    border: 1px dashed #CCC;
    border-radius: $border-radius;
    text-align: center;
    font-size: 10px;
    flex: 1 0 auto;

    &.has-media {
      font-size: 12px;
      border: none;
      padding: 0;
      text-align: left;
      // EAT-1638: make sure audio filenames greater than about 20 characters don't make the script panel too wide
      max-width: 125px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &:hover {
      border-color: #999;
    }
  }

  input,
  textarea,
  select {
    color: $gray;

    &:hover,
    &:focus {
      border-color: $gray;
    }

    &::-webkit-input-placeholder {
      color: $gray;
    }

    &:focus {

      &::-webkit-input-placeholder {
        color: rgba($gray, 0.5);
      }
    }
  }

  /* Hide scrollbars in Audio Row textareas */

  textarea::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  /* Darker React-Select component text color */

  .Select {

    > .Select-control {

      .Select-value
      .Select-value-label {
        color: $gray;
      }
    }
  }

  /* File picker and associated controls. */

  .file-list {
    display: flex;
    margin-top: 0;
    padding-bottom: 3px;
    padding-left: 2px;
    padding-top: 0;
    align-items: center;
    justify-content: space-between;

    &.ar-seperator {
      border-top: 1px solid #b9bbc0;
      margin-top:4px;
      padding-top:7px;
    }
    .start,
    .end {
      align-items: center;
      justify-content: flex-end;


      label {
        margin-right: 5px;
      }

      .fake-input,
      input {
        margin: 0;
        padding: 0;
        line-height: 1;
        border-bottom: 0;
      }

      .fake-input {
        cursor: default;
      }
    }

    .start {
      input {
        border: 1px solid grey;
        padding: 2px;
        border-radius: 3px;
        min-width: 25px;
      }
    }

    .end {
      margin-right: 0;
    }

    
    .media-relink {
      width: 12px;
      height: 12px;
      text-align: right;

    

      button {
        padding: 0;

        i {
          background-color: $gray;
          transition: background-color 0.2s;
          background-position: 0 -38px;

          &.raw-media{
            -webkit-mask-position: 0 -38px;
            -webkit-mask-position: 0 -38px;
          }
        }

      }
    }
  }
}

div.script-menu {
  background-image: url('../images/script-menu-light.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 4px 16px;
  cursor: pointer;
  display: inline-block;
  flex: 0 1 auto;
  height: 16px;
  margin-left: 5px;
  opacity: 0.3;
  position: relative;
  transition: opacity 0.2s, z-index 0;
  width: 20px;
  padding-right: 8px;
  margin-top: 3px;
  color: #0d0d0d;
  font-weight:bold;




  &.shown {
    opacity: 1 !important;
  }

  &:hover {
    opacity: 1 !important;

  }

  .popover {
    display: none;
    padding-right: 14px;
    padding-top: 20px;
    position: absolute;
    right: -14px;
    top: 0;
    width: 175px;
    z-index: 115;

    &.shown {
      display: block;
    }

    ol {
      background-color: rgba(242,242,242,0.9);
      border-radius: $border-radius;
      box-shadow: 0 0 30px rgba(0,0,0,0.3), 0 15px 12px rgba(0,0,0,0.2), inset 0 0 0 2px rgba(255, 255, 255, 1);
      padding: 5px 0;

      li {
        display: block;
        font-size: 12px;
        height: 26px;
        line-height: 26px;
        padding: 0 15px;
        width: 100%;

        &:hover {
          background-color: #999;
          color: #FFF;
        }

        &.divider {
          background-color: #979797;
          height: 1px;
          margin: 5px 0;
          opacity: 0.47;

          &:hover {
            background-color: #979797;
          }
        }
      }
    }
  }
}

.script-object.sequence {
  .inner {
    .flex {
      padding-left: 5px;
    }
  }
}

.script-object.chapter {

  .script-menu{

      margin-right: 4px;
    }

  .sequence-list {
    .script-menu{
      margin-right: 0;

      .row-list {
        .script-menu {
          margin-right: -4px;
        }
      }
    }
  }
}

.only-selected {
  display: none;
  &.chapter {
    display:block;
    opacity: .3;
    &.selected {
      opacity: 1;
    }
  }
  &.sequence {
    &.selected {
      display:block;
    }
  }
}
