// Pop-Up Modals
//
// Styleguide 2.2

// New Version Modal
//
// Modal appears upon selecting Create a new Version from the file menu.
// Markup:
// <div class="interfacegrey" style="padding:50px; width:650px; height:650px;" >
//    <div class="modal-wrap">
//       <div class="modal-content">
//        <header class="modal-header">
//          <h1></h1><button>Close</button>
//        </header>
//        <div class="newversion-wrap">
//           <label>What is the purpose of this version?</label>
//           <select>
//            <option value="">Select...</option>
//            <option value="major">Major Version</option>
//            <option value="minor">Minor Version</option>
//            <option value="working">Working Version</option>
//          </select>
//          <label>Add comments</label>
//          <textarea placeholder="*Required"></textarea>
//          <label class="validation"><span class="hidden">Please add comments and select a version type.</span></label>
//          <button><!-- react-text: 334 -->Create Version <!-- /react-text --><!-- react-text: 335 --><!-- /react-text --></button>
//         </div>
//       </div>
//    </div>
// </div>
//
// Styleguide 2.2.1

.newversion-wrap {
  color: $interfacegrey;
  font-family: 'Roboto';
  font-size: 16px;
  padding: 20px 0;
  text-align: left;
  width: 500px;

  textarea,
  select {
    border: 1px solid $interfacegrey;
    border-radius: 4px;
    color: $interfacegrey;
    float: left;
    font-weight: 400;
    line-height: 1.3em;
    padding: 5px 10px;
    width: 90%;

    &:hover {
      appearance: none;
      border: 1px solid $interfacegrey;
      border-radius: 6px;
      width: 90%;
    }
  }

  .assetset-label,
  .publishing {
    margin-left: 23px;

    label {
      margin-left: -23px;
    }
  }

  label {
    float: left;
    font-size: 24px;
    font-weight: 300;
    margin: 8px 5%;
    width: 100%;
  }

  textarea {
    color: $interfacewhite;
    height: 120px;
    margin: 10px 5% 0;
    padding: 10px;


    &::placeholder {
      color: $interfacegrey;
    }
  }

  select {
    @include chevron-select;;
    background-position: 98% 50%;
    height: 50px;
    margin: 10px 5% 30px;
    padding: 0 10px;

    &:hover {
      background-position: 98% 50%;
    }

    option {
      background: $interfacedarkgrey;
      font-weight: 400;
    }
  }

  .validation {
    height: 38px;
    margin: -1px 0 6px;
    padding: 0 0 0 30px;

    span {
      color: $interfacepink;
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
    }

    .hidden {
      display: none;
    }
  }

  button {
    border: 1px solid $interfacewhite ;
    border-radius: 4px;
    color: $interfacewhite ;
    display: block;
    font-weight: 500;
    margin: 20px auto;
    padding: 11px 45px;
    text-align: center;

    &:hover {
      background: $interfacegreen;
      border: 1px solid $interfacegrey;
      color: $interfaceblack;
    }
  }
}
