.Circle {
  fill: transparent;

  &.Chapter {
    stroke: $chapter;
    stroke-width: 3;
  }

  &.Sequence {
    stroke: $sequence;
    stroke-width: 3;
    stroke-dashoffset: 1;
    stroke-linecap: round;
  }

  &.DecisionNode {
    stroke: $node;
    stroke-width: 3;

    &.is-invalid {
      stroke: $node;
    }
  
    &.is-selected {
      stroke: $selection;
    }
  }

  &.StartNode {
    stroke: $node;
    stroke-width: 3;

    &.is-invalid {
      fill: $error;
    }

    &.is-selected {
      fill: $white;
    }

  }

  &.is-invalid {
    stroke: $error;
  }

  &.is-selected {
    stroke: $selection;
  }

}
.CircleDot {
  &.StartNode,
  &.Sequence{
   fill : $white;
  }
  display: none;
}

.Label {
  fill: $white;
  font-size: 50px;
  font-family: Roboto, sans-serif;
}

.ConnectionPath {
  fill: none;
  stroke-width: 1;

  &.Sequence {
    stroke: $sequence;
  }

  &.DecisionNode {
    stroke: $node;
  }

  &.StartNode {
    stroke: $light-gray;
  }

  &.is-invalid {
    stroke: $error;
  }

  &.is-selected {
    stroke: $selection;
  }

}

.ConnectionDot {
  // fill: none;

  &.Sequence {
    fill: $sequence;
  }

  &.DecisionNode {
    fill: $node;
  }

  &.StartNode {
    fill: $light-gray;
  }

  &.is-invalid {
    fill: $error;
  }

  &.is-selected {
    fill: $selection;
  }

}
