// Color Swatches - Used in Styleguide build

.colorswatch {
  border-radius: 10px;
  box-shadow: 2px 2px 2px $edm-black;
  color: $edm-white;
  display: block;
  float: none;
  margin: 10px 0;
  padding: 20px 0;
  text-align: center;
  width: 300px;
}

.colorswatch-bar {
  border-radius: 10px;
  box-shadow: 2px 2px 2px $edm-black;
  color: $edm-white;
  display: block;
  float: none;
  margin: 10px 0;
  padding: 5px 0;
  text-align: center;
  width: 800px;
}

.edm-darkgrey-bg {
  background: $edm-darkgrey;
  color: $edm-white;
}

.edm-coolgrey-bg {
  background: $edm-coolgrey;
  color: $edm-white;
}

.edm-grey-bg {
  background: $edm-grey;
  color: $edm-white;
}

.edm-lightgrey-bg {
  background: $edm-lightgrey;
  color: $edm-white;
}

.edm-coolgrey2-bg {
  background: $edm-coolgrey2;
  color: $edm-white;
}

.edm-white-bg {
  background: $edm-white;
  color: $edm-black;
}

.edm-magenta-bg {
  background: $edm-magenta;
  color: $edm-white;
}

.edm-gold-bg {
  background: $edm-gold;
  color: $edm-white;
}

.edm-black-bg {
  background: $edm-black;
  color: $edm-white;
}

.edm-mintgreen-bg {
  background: $edm-mintgreen;
  color: $edm-white;
}

.edm-colorbar-checkedouttoyou {
  background: $edm-colorbar-checkedouttoyou;
}

.edm-colorbar-checkedouttoother {
  background: $edm-colorbar-checkedouttoother;
}

.edm-colorbar-open {
  background: $edm-colorbar-open;
}

.edm-colorbar-archived {
  background: $edm-colorbar-archived;
}

.edm-scrollbar-bg {
  background: $edm-scrollbar-background;
}

.edm-scrollbar-bar {
  background: $edm-scrollbar-bar;
  color: $edm-black;
}

.edm-scrollbar-shadow {
  background: $edm-scrollbar-shadow;
}
