.viewport.layer{
    position:relative;
    .tooltip{
      position:absolute;
      background-color:white;
      padding:5px;
      border-radius:2px;
      font-size: 14px;
      line-height: 20px;
      margin-left:10px;
      min-height:30px;
      &::before{
        position: absolute;
        left: -20px;
        content: '';
        height: 0;
        width: 0;
        border: 10px solid transparent;
        z-index: 1;
        border-right-color: white;
      }
    }
  }