#loader {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  position: relative;

  .header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $takeover-border;
    width: 250px;
    position: relative;
    min-height: 48px;


    h1 {
      opacity: 1;
      transition: opacity 0.2s;
      margin-top: 0;
    }

    button.search {
      position: absolute;
      top: 1px;
      left: 65px;
      cursor: pointer;
      transition: left 0.5s;
    }

    .search-field {
      position: absolute;
      font-size: 24px;
      top: 0;
      left: 100px;
      opacity: 0;
      transition: all 0.5s;
      width: 175px;
    }

    .close-search {
      position: absolute;
      top: 4px;
      left: 295px;
      transition: all 0.5s;
      opacity: 0;

      &:hover {
        opacity: 0;
      }

    }

    &.searching {

      h1 {
        opacity: 0;
      }

      button.search {
        left: 0;
      }

      .search-field {
        left: 35px;
        opacity: 1;
      }

      .close-search {
        left: 230px;
        opacity: 0.3;

        &:hover {
          opacity: 0.5;
        }

      }
    }

  }

  .actions {
    height: 46px;
  }

  .load-button {
    font-size: 14px;
    background-color: $selection;
    color: $white;
    border-radius: $border-radius;
    padding: 15px 20px;
    transition: all 0.5s;
    margin-right: 15px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background: darken($selection, 10%);
    }

    &:disabled {
      background-color: $gray;
      opacity: 0.5;
    }
  }

  .cancel-button {
    font-size: 14px;
    font-weight: 500;
  }

  .pages {
    margin: 0 0 30px;
    text-align: center;
    font-size: 16px;
    color: $white;

    button {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: 1px solid $gray;
      padding: 0;
      margin: 0 5px;
    }

    .active {
      border: none;
      background-color: $white;
    }

  }

  .editions {
    flex: 1 1 0;
    display: flex;
    overflow: hidden;
    margin: 0 0 20px;

    .wrapper {
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      align-items: flex-start;
      flex-flow: column wrap;
      align-content: flex-start;
      transition: transform 0.5s;
      @extend .disable-selection;

      li {
        padding: 3px 10px;
        font-weight: 500;
        width: 25%;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        &:nth-child(even) {
          background-color: rgba(0,0,0,0.15);
        }

        button {
          padding: 0;
          color: $gray;
          transition: color 0.2s;
          text-align: left;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
          width: 100%;

          &:hover {
            color: $white;
          }

          &.selected,
          &.selected > span {
            color: $selection;
          }

          > span {
            @extend .truncate;
            display: block;

            &.title {
              flex: 1 0 0;
            }

            &.updated {
              font-weight: 300;
              text-align: right;
              display: block;
              width: 100px;

            }

          }


        }

      }

    }

  }

  .sort {
    position: absolute;
    top: 0;
    right: 0;
    color: $gray;

    button {
      padding: 0 0 0 2px;

      &.active {
        color: $selection;
      }
    }
  }

}
