.menu-set {
  position: relative;

  .menu {
    position: fixed;
    z-index: 101;
    width: 250px;
    height: 350px;
    box-shadow: 0 0 30px rgba(0,0,0,0.3), 0 15px 12px rgba(0,0,0,0.2), inset 0 0 0 2px rgba(255, 255, 255, 1);
    border-radius: $border-radius;
    background-color: rgba(242,242,242,0.9);
    transform: translateY(-5px);
    display: flex;
    flex-direction: column;

    &.split {
      width: 200px;
      border: none;
      box-shadow: none;
      background-color: transparent;

      .workspace {
        box-shadow: 0 0 30px rgba(0,0,0,0.3), 0 15px 12px rgba(0,0,0,0.2), inset 0 0 0 2px rgba(255, 255, 255, 1);
        background-color: rgba(242,242,242,0.9);
        border-radius: $border-radius;
        padding: 10px;
        margin-bottom: 5px;

        &.script {
          .radio-group {
            width: 160px;
          }
        }

        &.right {
          .radio-group {
            width: 100px;
          }
        }

        .radio-group {
          padding-top: 5px;
          display: flex;
          justify-content: space-between;
          label {
            display: block;
            font-size: 12px;
          }
        }

        label {
          font-size: 14px;

          input {
            margin-right: 5px;
          }

        }

      }


    }

  }


  div.search {
    flex: 0 1 0;
    min-height: 50px;
    max-height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;

    > span {
      margin: 0;
      i {
        color: $black;
      }
    }

    input {
      border-color: transparent;
      color: $black;
      font-size: 14px;
      border: none;
      border-bottom: 0;
      padding: 0 0 0 5px;
      margin: 0;

      &::placeholder {
        font-weight: $font-light;
        color: $black;
      }

      &:focus {
        border: none;
        padding: 0 0 0 5px;
      }

    }

  }

  div.recent {
    border-bottom: 3px solid $white;

    label {
      background-color: $white;
      display: block;
      padding: 3px 10px;
    }

  }

  .body {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid $white;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        -webkit-border-radius: 0px;
        border-radius: 0px;
        background: rgba($white, 0);
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        border: 3px solid transparent;
        background: rgba($gray,0.8);
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba($gray,0.4);
    }

  }

  .empty {
    color: $black;
    padding: 25px 0;
    font-size: 14px;
  }

  ul {

    li {
      padding: 10px;
      border-bottom: 1px solid $white;
      cursor: pointer;

      &:hover {
        background-color: $white;
      }

      &:last-child {
        border-bottom: 0;
      }

    }

  }

}
