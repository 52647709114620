// Styles for Error Modal
.error-modal-container {
  flex: 0 1 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
}

.error-modal-wrap {
  flex: 1 1 auto;
  position: relative;
  height: 100%;
  margin-right: 15px;
}

.error-modal-message {
  height: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  white-space: pre-wrap;
  font-size: 11px;
  line-height: 1.5;
  color: rgb(41, 50, 56);
  background-color: hsl(0, 100%, 97%);
  border-radius: 0.25rem;
  padding: 10px;
  font-family: 'Roboto Mono', Consolas, Menlo, monospace;
}
