// Dimensions
$timeline-label-width: 250px;
$timeline-pad-right: 15px;
$row-height: 20px;
$control-height: 45px;
$ruler-height: 35px;
$playhead-width: 24px;
$timeline-content-height: 100px;

// Colors
$control-gray: #abacae;
$properties: #898b8e;
$animate-base: #d3d4d6;

@keyframes svgFillPulse {
  0% { fill-opacity: 1; }
  50% { fill-opacity: .6; }
  100% { fill-opacity: 1; }
}

#timeline {
  align-content: stretch;
  align-items: stretch;
  background-color: $timeline-background;
  border: 1px solid transparent;
  box-shadow: 0 -6px 8px $black35percent;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  position: absolute;
  width: 100%;
  overflow: hidden;

  * {
    @extend .disable-selection;
  }

}

.playhead {
  position: absolute;
  cursor: default;
  z-index: 10;
  left: $timeline-label-width;
}

.keyframe {
  fill: $white;
  &.selected {
    fill: $selection;
    fill-opacity: 1;
    &.dragging {
      animation: svgFillPulse 2s infinite;
    }
  }
}

.keyframe-hit-area {

  &.selected {
    cursor: grab;
    
    &.dragging {
      cursor: grabbing;
    }
  }
}

@import 'timeline-controls';

.timeline-ruler {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex: 1 0 $ruler-height;
  flex-direction: row;
  justify-content: flex-end;
  max-height: $ruler-height;
  position: relative;
  width: 100%;

  .ruler {
    width: calc(100% - (250px + 24px)); //$timeline-label-width + $playhead-width (sucks vars don't work here...)
    margin-right: $playhead-width;
  }

  .viewable {
    width: 100%;
  }



}

.timeline-content {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: $timeline-pad-right;

  .labels {
    display: block;
    flex: 0 0 $timeline-label-width;
    height: 100%;
    width: $timeline-label-width;
  }

  .timelines {
    display: block;
    flex: 1 0 0;
    height: 100%;
  }

}

.master-content {
  @extend .timeline-content;

  flex: 0 10 20px;
  min-height: 20px;

  .labels {
    padding-bottom: 0;

    .actions-labels,
    .audio-labels {
      margin-bottom: 0;
    }

  }

  .timelines {
    padding-bottom: 0;
  }

}
.asset-container{
  position: relative;
  .timeline-content {
    height: calc(100% - 36px);
  }
  .grabber.horizontal{
    border-top: 1px solid #AAAEB3;
    height: 5px;
    top:0px;
    padding: 0px;
    margin: 5px;
  }
  & > .asset-labels{
    padding-top:10px;
    & > .asset-label.master{
      width:250px;
      padding-right:21px;
    }
  }
}

#timeline.free > .asset-container {
  height: 90%;
 .timeline-content {
   height: 95%;
 }
}

.actions-labels,
.audio-labels,
.asset-labels {
  padding-left: 15px;

  &.inset {
    margin-left: 17px;
    margin-bottom: 0;
  }

  .children {
    padding-left: 17px;
  }

  .actions-label,
  .audio-label,
  .asset-label {
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    display: flex;
    height: $row-height;
    padding: 0 7.5px 0 0;
    width: 100%;

    > i.sprite {
      margin-right: 5px;
    }

    .icon {
      margin-right: 5px;
      display: flex;
      align-items: center;
    }

    .indicator {
      padding: 0;
      width: 12px;
      margin-right: 5px;
      opacity: .6;
      transform: rotate(-90deg);
      width: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s;

      > i {
        padding: 0;
      }

      &:disabled {
        opacity: 0.1;
        cursor: not-allowed;
      }

    }

    &.master {

      .asset-name {
        font-weight: $font-bold;
      }

    }

    .asset-name {
      cursor: pointer;
      flex: 1 1 auto;
      font-size: 12px;
      margin-right: 5px;
      overflow: hidden;
      align-items: center;
      display: flex;

      .truncate {
        width: 100%;
        display: block;
        color: $white;
      }

    }

    .drop-target {
      padding: 0;
      display: flex;

      &.drop-enabled {

        .property-menu {

          i {
            background-color: $white;
          }

        }

      }

    }

    .property-menu {

      padding: 0;
      display: flex;
      margin-right: 2px;

      i {
        background-color: rgba($gray, 0.25);
        transition: background-color 0.2s;
      }

      &:hover {

        i {
          background-color: rgba($gray, 0.75);
        }

      }

    }

    .action {
      min-width: 16px;
      padding: 1px;
      text-align: center;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        background-color: #50545A;
        transition: background-color 0.2s;
        padding: 0 !important;
        margin: 0 !important;
        flex: none;
      }

      &.active {

        i {
          background-color: $white;
        }

      }

      &:hover {

        i {
          background-color: $white;
        }

      }

    }

    button.label-toggle {
      color: $background;
      background: $gray;
      font-weight: $font-regular;
      font-size: 9px;
      border-radius: $border-radius;
      padding: 2px 0;
      margin-right: 5px;
      width: 34px;
      text-align: center;
    }

    button.clone {
      padding: 0;
    }

    button.clone,
    button.delete {
      opacity: 0.2;

      &:hover {
        opacity: 1;
      }

    }

    button.hide,
    button.lock {
      opacity: 0.2;

      &:hover {
        opacity: 0.5;
      }

    }

    button.hiding,
    button.locked {
      opacity: 1;

      &:hover {
        opacity: 1;
      }

    }

    button.hide {
      padding: 0;
    }

    &.expanded {

      .indicator {
        opacity: 1;
        transform: rotate(0);
      }

    }

    &.selected {

      .asset-name,
      .asset-name > span {
        color: $green;
      }

      .timeline-icon {

        .rect {

          &.filled {
            background-color: $selection;
          }

          &.empty {
            border: 1px solid $selection;
          }

        }

      }


    }

  }

}


.asset-properties {
  padding-left: 18px;

  .asset-property {
    display: flex;
    height: $row-height;
    padding: 0;
    width: 100%;
    opacity: 1;
    align-items: center;

    &.selected {
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 100%);

      .property-icon {

        &:hover {

          .rect.filled {

            background-color: lighten($selection, 25%)

          }

        }

        &:active {

          .rect.filled {

            background-color: $white

          }

        }

        .rect {
          &.filled {
            background-color: $selection;
          }

          &.empty {
            border-color: $selection;
          }

        }


      }

      .property-label {
        color: $selection;
      }

      .keyframe-value {
        color: $white;

        input[type="text"],
        select {
          color: $white;
        }

      }

    }

    &.behavior {
      height: $row-height * 2 - 2px;
    }

    &.locked {
      opacity: 0.5;
    }

    &.spacer {
      margin-bottom: 0;
    }

    &:hover {

      .property-label {

        i {
          background-color: rgba($properties, 0.5);
        }

      }

    }

    .property-icon {
      cursor: pointer;
      flex: 1 0 auto;
      height: 12px;
      margin-right: 6px;
      max-width: 12px;
      padding: 4px 3px;
      width: 12px;

      &:hover {

        .rect.filled {

          background-color: lighten($properties, 25%)

        }

      }

      &:active {

        .rect.filled {

          background-color: $white

        }

      }


      .rect {
        display: block;
        height: 6px;
        transform: rotate(45deg);
        width: 6px;

        &.filled {
          background-color: $properties;
        }

        &.empty {
          border: 1px solid $white;
        }

      }

    }

    .property-label {
      color: $properties;
      flex: 1 0 auto;
      font-size: 12px;

      i {
        background-color: rgba($properties, 0.1);
      }

      button {
        padding: 0;
        margin-left: 5px;

        &.active {

          i {
            background-color: $white;
          }

        }

      }

    }

    .keyframe-value {
      flex: 1 0 auto;
      width: 100px;
      max-width: 100px;
      margin-right: 10px;
      text-align: right;
      color: $properties;
      font-size: 11px;
      line-height: 12px;
      .link-inputs {
        padding: 0;
        margin-right: 2px;
        i {
          transition: color 0.2s;
        }
        &.off i {
          color: #50545A;
        }
        &.on i {
          color: white;
        }
      }
    }

    .keyframe-action {
      height: 20px;
      flex: 0 1 auto;
      margin-top: 0;
      margin-right: 0;
      padding: 0 2px;
      background-image: url('../images/timeline-sprites.png');
      background-size: 198px 94px;

        &.add {
          width: 12px;
          background-position: -16px -72px;
        }

        &.remove {
          width: 12px;
          background-position: -29px -72px;
        }

        &.prev,
        &.next {
          width: 9px;
          margin: 0;
          opacity: 0.5;
          transition: opacity 0.1s;

          &:hover {
            opacity: 1;
          }

        }

        &.prev {
          background-position: -42px -72px;
        }

        &.next {
          background-position: -51px -72px;
        }

    }

  }

}

.keyframe-value {

  .inputs {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > div {
      flex: 0 1 auto;
    }

  }

  input[type="text"],
  select {
    min-height: 0;
    text-align: right;
    color: $properties;
    font-size: 11px;
    padding: 0;
    background: transparent;

    &:focus,
    &.active {
      background: transparent;
    }

    &:disabled {
      &:hover {
        border-bottom: none;
      }
    }

  }

  select {
    direction: rtl;
  }

}

.audio-timeline {
  height: $row-height;

  &.selected {

    .row-rect {
      fill: $selection;
      fill-opacity: 1;
    }

  }

  .selected {
    fill: $selection;
    fill-opacity: 1;
    cursor: grab;
  }

  .selected.dragging {
    cursor: grabbing;
    animation: svgFillPulse 3s infinite;
  }

  .grid {

      svg {
        display: block;
      }

  }

}


.asset-timeline {
  height: $row-height;
  display: block;
  .selected {
    fill: $selection;
    cursor: grab;
  }
  .selected.dragging {
    cursor: grabbing;
    animation: svgFillPulse 3s infinite;
  }
  .grid {
    position: relative;
  }
}

.property-timeline {
  height: $row-height;
  .grid {
    position: relative;
    height: $row-height;
    svg {
      position: absolute;
      &.above {
        z-index: 1000;
      }
    }
  }
}

.property-behavior {
  height: ($row-height * 2) - 2;
  background-color: lighten($background, 5%);
  border: 1px solid $background;
  position: relative;
  overflow-y: scroll;

  &.error {

    > div {
      color: $error;
    }

  }

  > div {
    color: $white;
    font-family: 'Roboto Mono';
    font-weight: 100;
    font-size: 10px;
    padding: 2px 5px;
  }

  .compile-error {
    position: absolute;
    top: 3px;
    right: 0px;
    padding: 2px 15px 2px 10px;
    background: $error;
    color: $white;
  }

}

#selection-gradient {
  .stop-top {
    stop-color: #03FF94;
  }
  .stop-bottom {
    stop-color: #02E888;
  }
}

.animate-mode {
  .timeline-controls,
  .timeline-ruler {
    background-color: $animate-base;
  }
}


.scrubhead {
  cursor: grab;
  pointer-events: all;
}
