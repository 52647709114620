.progress-bar {
  width: 100%;
  text-align: center;

  .progress-bar-outer {
    width: 100%;
    background: rgba($black, 0.05);
    display: block;
    height: 15px;
    border-radius: $border-radius;
    border: 1px solid rgba($black, 0.25);

    .progress-bar-inner {
      display: block;
      background-color: $green;
      height: 15px;
      border-radius: $border-radius;
    }

  }

  .progress-bar-meta {
    margin-top: 5px;
    font-size: 12px;
  }

}