@charset 'UTF-8';

/* Reset */
@import './vendor/normalize';

/* Global Partials */
@import './global/colors';
@import 'fonts';
@import 'typography';
@import 'icons';
@import 'utilities';
@import 'scaffolding';

/* Mixins */
@import './mixins/icons';

/* component styles */
@import 'spinner';
@import 'sprites';
@import 'cards';
@import 'toolbar';
@import 'menu';
@import 'select';
@import 'progress-bar';
@import 'code';
@import 'error';
@import 'slider';
@import 'context-menu';
@import 'stage-assets'; // default asset states (disabled, hover, active, focus)

/* Card Specific Partials */
@import 'viewport';
@import 'connections';
@import './panels/details-card';
@import './panels/hierarchy-card';
@import './panels/media-card';
@import './panels/script-card';
@import './panels/timeline';
@import './panels/widgets-card';
@import './panels/actions-card';
@import './panels/variables-card';
@import 'structure';
@import 'modal';
@import 'fileuploadmodal';
@import 'takeover';
@import 'tooltip';
// @import 'import';
@import 'loader';
@import 'drop';
@import 'blender';

@import './containers/editionmanager/editionmanager';
@import './containers/interface/interface';

/* Variables */

html,
body {
  background-color: $takeover-background;
  height: 100%;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.rotate {
  opacity: 25;

  &:hover {
    cursor: -webkit-grab;
  }
}

.scale {
  opacity: 25;

  &.top,
  &.bottom {
    &:hover {
      // @extend .cursor-scaling-y;
      cursor: ns-resize;
    }
  }

  &.right,
  &.left {
    &:hover {
      // @extend .cursor-scaling-x;
      cursor: ew-resize;
    }
  }

  &.ul {
    &:hover {
      cursor: nw-resize;
    }
  }

  &.ur {
    &:hover {
      cursor: ne-resize;
    }
  }

  &.lr {
    &:hover {
      cursor: se-resize;
    }
  }

  &.ll {
    &:hover {
      cursor: sw-resize;
    }
  }
}

.selection {
  &:hover {
    border: 2px solid rgba($green, 1) !important;
    cursor: default;
  }
}

.cursor-rotating {
  cursor: -webkit-grabbing !important;
}
