$slider-track-height:  6px !default;
$slider-thumb-height:  16px !default;
$slider-thumb-width:   16px !default;
$color-slider-track:   #D3E2EB !default;
$color-slider-thumb:   #86B85F !default;

#viewport {
  input[type=range] {
    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    border: 1px solid white;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: $slider-track-height;
    background: $color-slider-track;
    border: none;
    border-radius: 4px;
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: $slider-thumb-height;
    width: $slider-thumb-width;
    border-radius: 50%;
    background: $color-slider-thumb;
    margin-top: calc(-1 * ($slider-thumb-height - $slider-track-height) / 2);
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: $slider-track-height;
    background: $color-slider-track;
    border: none;
    border-radius: 4px;
  }
  input[type=range]::-moz-range-thumb {
    border: none;
    height: $slider-thumb-height;
    width: $slider-thumb-width;
    border-radius: 50%;
    background: $color-slider-thumb;
  }

  /*hide the outline behind the border*/
  input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: $slider-track-height;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: $slider-track-height 0;

    /*remove default tick marks*/
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: $color-slider-track;
    border-radius: 4px;
  }
  input[type=range]::-ms-fill-upper {
    background: $color-slider-track;
    border-radius: 4px;
  }
  input[type=range]::-ms-thumb {
      border: none;
      height: $slider-thumb-height;
      width: $slider-thumb-width;
      border-radius: 50%;
      background: $color-slider-thumb;
  }

  /* THEME A */
  input[type=range].theme_A::-webkit-slider-runnable-track {
    height: 20px;
  }

  input[type=range].theme_A::-webkit-slider-thumb {
    margin-top: calc(-1 * ($slider-thumb-height - 20px) / 2);
  }

  input[type=range].theme_A::-webkit-slider-thumb {
    background: #3e59b5;
  }

  /* THEME B */
  input[type=range].theme_B::-webkit-slider-thumb {
    background: #e74c3c;
  }

  /* THEME C */
  input[type=range].theme_C::-webkit-slider-thumb {
    height: 30px;
    width: 15px;
    border-radius: 3px;
    margin-top: calc(-1 * (30px - $slider-track-height) / 2);
    background: #f39c12;
  }

  input[type=range].theme_C::-webkit-slider-runnable-track {
    background: #1abc9c;
  }
}
